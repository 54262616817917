import React, { useState } from "react";
import Blogs from "./Post";
import MyCarousel from "../../Components/ReuseableComponentWeb/ReviewCrousel";

function BlogScreen() {
  const [isDetailOpen, setIsDetailOpen] = useState(false);

  const handleDetailToggle = (isOpen) => {
    setIsDetailOpen(isOpen);
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
      {/* {!isDetailOpen && (
        <div style={{ width: "100%", margin: "2vw 0" }}>
          <MyCarousel />
        </div>
      )} */}
      <div>
        <Blogs onDetailToggle={handleDetailToggle} />
      </div>
    </div>
  );
}

export default BlogScreen;
