import React, { useState, useEffect, useRef } from "react";
import useScreenSizes from "../../utils/useScreenSize";
import ERPContainer from "./ERP";
import Website from "./Website";
import LMSContainer from "./LMS";
import { useLocation } from "react-router-dom";
import SmartClass from "./SmartClass";
import SmartSchool from "./SmartSchool";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { fontFamily } from "@mui/system";

const Solutios = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [divColor, setDivColor] = useState("black");
  const screenType = useSelector((state) => state.website.screenType);
  const screen = useScreenSizes();
  const erpRef = useRef(null);
  const websiteRef = useRef(null);
  const lmsRef = useRef(null);
  const smartschoolRef = useRef(null);
  const smartclassRef = useRef(null);
  const sectionRefs = [
    erpRef,
    websiteRef,
    lmsRef,
    smartclassRef,
    smartschoolRef,
  ];

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            if (entry.target.id === "erp") {
              setActiveIndex(0); // ERP section is visible
            } else if (entry.target.id === "website") {
              setActiveIndex(1); // Website section is visible
            } else if (entry.target.id === "lms") {
              setActiveIndex(2); // LMS section is visible
            } else if (entry.target.id === "smartclass") {
              setActiveIndex(3); // LMS section is visible
            } else if (entry.target.id === "smartschool") {
              setActiveIndex(4); // LMS section is visible
            }
          }
        });
      },
      { threshold: 0.1 } // Adjust based on when you want to trigger the tab change
    );

    // Observe the sections
    sectionRefs.forEach((ref) => {
      if (ref.current) observer.observe(ref.current);
    });

    return () => {
      // Cleanup the observer when component unmounts
      sectionRefs.forEach((ref) => {
        if (ref.current) observer.unobserve(ref.current);
      });
    };
  }, []);

  const activateTab = (index) => {
    setActiveIndex(index); // Update the active tab

    // Scroll to the selected section (when clicking)
    sectionRefs[index].current.scrollIntoView({ behavior: "smooth" });
  };

  const [opacity, setOpacity] = useState(1);
  const [isInView, setIsInView] = useState(false);

  const [hasAnimated, setHasAnimated] = useState(false); // Track if animation has run
  const containerLeftRef = useRef(null);
  const containerRightRef = useRef(null);
  const [lastScrollY, setLastScrollY] = useState(0); // Track last scroll position
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting && !hasAnimated) {
          // Check if not animated yet
          setIsInView(true);
          setHasAnimated(true); // Set animated to true after first animation
        }
      });
    });

    if (containerLeftRef.current) observer.observe(containerLeftRef.current);
    if (containerRightRef.current) observer.observe(containerRightRef.current);

    return () => {
      if (containerLeftRef.current)
        observer.unobserve(containerLeftRef.current);
      if (containerRightRef.current)
        observer.unobserve(containerRightRef.current);
    };
  }, [hasAnimated]);
  const location = useLocation();

  useEffect(() => {
    if (location.state) {
      const { section } = location.state;

      switch (section) {
        case "LMS":
          setTimeout(() => {
            lmsRef.current.scrollIntoView({ behavior: "smooth" });
          }, 200);
          break;
        case "ERP":
          setTimeout(() => {
            erpRef.current?.scrollIntoView({ behavior: "smooth" });
          }, 200);
          break;
        case "WEBSITE":
          setTimeout(() => {
            websiteRef.current?.scrollIntoView({ behavior: "smooth" });
          }, 200);
          break;
        case "SMARTCLASS":
          setTimeout(() => {
            smartclassRef.current?.scrollIntoView({ behavior: "smooth" });
          }, 200);
          break;
        case "SMARTSCHOOL":
          setTimeout(() => {
            smartschoolRef.current?.scrollIntoView({ behavior: "smooth" });
          }, 200);
          break;

        default:
          break;
      }
    }
  }, [location]);
  const handleTabClick = (index) => {
    if (index === 0 && erpRef.current) {
      erpRef.current.scrollIntoView({ behavior: "smooth" });
    } else if (index === 1 && websiteRef.current) {
      websiteRef.current.scrollIntoView({ behavior: "smooth" });
    } else if (index === 2 && lmsRef.current) {
      lmsRef.current.scrollIntoView({ behavior: "smooth" });
    } else if (index === 3 && smartclassRef.current) {
      smartclassRef.current.scrollIntoView({ behavior: "smooth" });
    } else if (index === 4 && smartclassRef.current) {
      smartschoolRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  // Style variables
  const fixedSectionStyle = {
    height: screenType === "tab" ? "3vh" : "5vh",
    width: "100%",
  };

  const navbarStyle = {
    position: "fixed",
    left: 0,
    top:
      screenType === "smallPhone" ||
      screenType === "phone" ||
      screenType === "tab" ||
      screenType === "tablet"
        ? ""
        : "14vh",
    height: "auto",
    width: "100%",
    background: "rgb(161 217 255)",
    zIndex: 7,
  };

  const ulStyle = {
    display: "flex",
    listStyleType: "none",
    marginBlockStart:
      screenType === "smallPhone" ||
      screenType === "phone" ||
      screenType === "tab" ||
      screenType === "tablet"
        ? ""
        : "3em",
    marginBlockEnd:
      screenType === "smallPhone" ||
      screenType === "phone" ||
      screenType === "tab" ||
      screenType === "tablet"
        ? ""
        : "1em",
    paddingInlineStart: 0,
    marginBottom: 0,
    paddingLeft:
      screenType === "smallPhone" || screenType === "phone" ? "" : "40px",
    alignItem: "end",
    overflowX: "scroll",
  };

  const liStyle = {
    display: "flex",
    listStyle: "none",
    alignItems: "center",
    padding: screenType === "smallPhone" ? "0px 28px" : "0px 60px",
    fontSize: "1rem",
    borderRadius: "20px 20px 0px 0px",
    position: "relative",
    cursor: "pointer",
    height: "40px",
  };

  const activeLiStyle = {
    ...liStyle,
    background: "#fff",
  };

  const linkStyle = {
    textDecoration: "none",
    color: "#fff",
    fontFamily: "lemonfont",
  };

  const activeLinkStyle = {
    ...linkStyle,
    color: divColor,
  };

  const circleStyle = {
    content: '""',
    height: "30px",
    width: "30px",
    position: "absolute",
    background: "rgb(161 217 255)",
    borderRadius: "50%",
    boxShadow: "15px 15px 0 #fff",
  };
const {t} = useTranslation();
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        fontFamily: "raleway",
      }}
    >
      <div style={fixedSectionStyle}></div>
      <div style={navbarStyle}>
        <ul style={ulStyle}>
          {["ERP", "WEBSITE", "LMS", "SMARTCLASS", "SMARTSCHOOL"].map(
            (item, index) => (
              <li
                key={index}
                style={activeIndex === index ? activeLiStyle : liStyle}
                onClick={() => handleTabClick(index)}
              >
                <a style={activeIndex === index ? activeLinkStyle : linkStyle}>
                  {t(item)}
                  {activeIndex === index && (
                    <>
                      <span
                        style={{ ...circleStyle, left: "-30px", bottom: 0 }}
                      ></span>
                      <span
                        style={{
                          ...circleStyle,
                          right: "-30px",
                          bottom: 0,
                          boxShadow: "-15px 15px 0 #fff",
                        }}
                      ></span>
                    </>
                  )}
                </a>
              </li>
            )
          )}
        </ul>
      </div>
      <div style={{ flex: 1 }}>
        <div
          style={{
            height:
              screenType === "tab" || screenType === "tablet"
                ? "35vw"
                : screenType === "smallPhone" || screenType === "phone"
                ? ""
                : "60vh",
            width: "95%",
            position: "relative",
            backgroundColor: "white",
            borderRadius: "10px",
            margin: "2vw auto",
            padding: "15px",
            display: "flex",
            flexDirection:
              screenType === "phone" || screenType === "smallPhone"
                ? "column"
                : "row",
            boxShadow: "10px 10px 15px rgba(0, 0, 0, 0.3)",
            marginTop:
              screenType === "smallPhone" ||
              screenType === "phone" ||
              screenType === "tab" ||
              screenType === "tablet"
                ? "10vh"
                : "5vh",
            overflow: "hidden",
          }}
        >
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundImage: `url(${require("../../assets/images/forsolutionBG.png")})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              opacity: 0.3, // Set the opacity of the image
              zIndex: 0, // Ensure it stays behind the content
            }}
          ></div>
          <div
            style={{
              height: "100%",
              width:
                screenType === "smallPhone" || screenType === "phone"
                  ? ""
                  : "50%",
              transform: isInView ? "translateX(0)" : "translateX(100%)",
              opacity: isInView ? 1 : 0,
              transition:
                "transform 0.5s ease-in-out, opacity 0.5s ease-in-out",
              borderRadius: "10px",
              display: "flex",
              alignItems: "center",
            }}
            ref={containerLeftRef}
          >
             <p  style={{
                  fontSize:
                    screenType === "smallPhone" || screenType === "phone"
                      ? "30px" :
              screenType === "tab" ?"25px" : screenType === "tablet" ? "30px"
                      : "40px",
                  fontFamily: "'Roboto', sans-serif",
                  fontWeight: "600",
                  lineHeight: "1.5",
                  textAlign: "center",
                  color: "#2E3A59",
                  margin: "20px",
                  textShadow: "1px 1px 2px rgba(0, 0, 0, 0.2)",
                }} >
             {t( "Comprehensive Development Solutions for Every Stage – From Concept to Execution, We've Got You Covered.")}
            </p>
          </div>
          <div
            style={{
              height: "100%",
              width:
                screenType === "smallPhone" || screenType === "phone"
                  ? ""
                  : "50%",

              transform: isInView ? "translateY(0)" : "translateY(100%)",
              opacity: isInView ? 1 : 0,
              transition: "transform 1s ease-in-out, opacity 1s ease-in-out",
            }}
            ref={containerRightRef}
          >
            <img
              src={require("../../assets/images/SOLUTION.png")}
              style={{
                height: "100%",
                width: "100%",
                objectFit: "contain",
              }}
            />
          </div>
        </div>
        <div
          style={{
            padding:
              screenType === "smallPhone" || screenType === "phone"
                ? ""
                : "20px",
          }}
        >
          <div id="erp" ref={erpRef} data-index="0" style={{ padding: "40px" }}>
            <ERPContainer />
          </div>
          <div
            id="website"
            ref={websiteRef}
            data-index="1"
            style={{ padding: "40px" }}
          >
            <Website />
          </div>
          <div id="lms" ref={lmsRef} data-index="2" style={{ padding: "40px" }}>
            <LMSContainer />
          </div>
          <div
            id="smartclass"
            ref={smartclassRef}
            data-index="3"
            style={{ padding: "40px" }}
          >
            <SmartClass />
          </div>
          <div
            id="smartschool"
            ref={smartschoolRef}
            data-index="4"
            style={{ padding: "40px" }}
          >
            <SmartSchool />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Solutios;
