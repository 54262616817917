import React, { useEffect, useRef, useState } from "react";
import useScreenSizes from "../../utils/useScreenSize";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const cardData = [
  {
    title: "Comprehensive class management",
    smallCards: [
      {
        src: require("../../assets/images/Streamlined scheduling.png"),
        title: "Streamlined Scheduling",
        content:
          "Effortlessly organise and update class timetables with just a few clicks. The intuitive interface allows teachers to create and modify schedules quickly, reducing the time spent on administrative tasks. This means more time can be dedicated to teaching and engaging with students, leading to a smoother learning experience",
      },
      {
        src: require("../../assets/images/Easy Lesson plannig.png"),
        title: "Easy Lesson Planning",
        content:
          "Create, adjust, and share lesson plans quickly, keeping everything in one place. The platform offers a user-friendly template for lesson planning that makes it simple to tailor content to different learning needs. Teachers can collaborate and share plans with colleagues, ensuring a cohesive approach to education while maintaining flexibility.",
      },
      {
        src: require("../../assets/images/Assignment Tracker.png"),
        title: "Assignment Tracking:",
        content:
          "Monitor student progress on assignments in real-time, ensuring timely feedback. This feature allows teachers to easily see which assignments have been submitted and which students may need additional support. By providing immediate feedback, educators can enhance student learning and engagement, ultimately driving better academic outcomes.",
      },
      {
        src: require("../../assets/images/Centralized Resources.png"),
        title: "Centralised Resources",
        content:
          "Store all teaching materials in one platform for easy access during lessons. With a centralised repository, teachers can quickly find and utilise resources such as videos, worksheets, and presentations. This saves time and effort during lesson preparation and allows for a more dynamic and interactive classroom experience.",
      },
      {
        src: require("../../assets/images/Autimated Reminder.png"),
        title: "Automated Reminders",
        content:
          "Set automatic notifications for deadlines, tests, and class activities, keeping students on track. This feature reduces the chances of missed assignments or important dates by sending timely reminders to both students and parents. By keeping everyone informed, it fosters accountability and ensures that students remain engaged in their learning journey.",
      },
      {
        src: require("../../assets/images/Attendance Mangement.png"),
        title: "Attendance Management",
        content:
          "Keep track of attendance records digitally, minimising paperwork. This streamlined approach simplifies the attendance process, allowing for quick updates and easy reporting. Digital attendance records enhance accuracy and save teachers valuable time that can be better spent on student interactions and instructional planning.",
      },
      {
        src: require("../../assets/images/Seamless communication.png"),
        title: "Seamless Communication",
        content:
          "Instantly communicate with students and parents about updates or concerns within the platform. This feature fosters an open line of communication, allowing educators to share important announcements, answer questions, and address any issues promptly. Improved communication strengthens relationships and keeps everyone connected in the educational process.",
      },
      {
        src: require("../../assets/images/Data Driven insights(1).png"),
        title: "Data-Driven Insights",
        content:
        "Analyse student performance data to improve lesson effectiveness and personalise learning strategies. By leveraging data analytics, teachers can identify trends and areas for improvement, allowing them to tailor instruction to meet individual student needs. This focus on data-driven decisions enhances the overall quality of education and supports student success.",
      },
     
 ],

 },

 {
  title: "data driven insights ",
  smallCards:[
    {
      src: require("../../assets/images/Insdividual Progress Reports.png"),
      title: "Individual Progress Reports",
      content:
        "Access detailed reports for each student that spotlight their unique strengths and pinpoint areas for improvement. These personalised insights allow educators to celebrate achievements and tailor their teaching strategies, ensuring each student receives the attention they need to thrive academically.",
    },
 
    {
      src: require("../../assets/images/Class wide Trends.png"),
      title: "Class-Wide Trends",
      content:
        "Analyse overall class performance to uncover common challenges that may be hindering student success. By identifying trends, teachers can adjust lesson plans proactively, targeting specific areas where students struggle, and enhancing the overall learning experience for the entire class.",
    },
    {
      src: require("../../assets/images/Targeted supports suggestion.png"),
      title: "Targeted Support Suggestions",
      content:
        "Receive data-backed recommendations designed to offer personalised support to students who are struggling. This targeted approach ensures that interventions are timely and effective, fostering a supportive learning environment that empowers every student to overcome obstacles and achieve their potential",
    },
    {
      src: require("../../assets/images/track learning milestones.png"),
      title: "Track Learning Milestones",
      content:
        "Monitor students’ progress over time to ensure they meet key learning objectives efficiently. By tracking these milestones, educators can assess the effectiveness of their teaching methods, make informed adjustments, and keep students motivated as they advance toward their academic goals.",
    },
  ]
 },
 {
  title: "Flexible content delivery ",
  smallCards:[
    {
      src: require("../../assets/images/Multi format support.png"),
      title: "Multi-Format Support",
      content:
        "Easily share a variety of educational materials, including videos, presentations, PDFs, and more, to cater to diverse learning preferences. This flexibility ensures that all students can engage with content in a way that resonates with them, fostering a more inclusive learning environment",
    },
    {
      src: require("../../assets/images/interactaive Learning.png"),
      title: "Interactive Learning",
      content:
        "Ensure no message is missed with automatic alerts for new communications, keeping everyone informed. These notification alerts help maintain a continuous flow of information, ensuring that students and parents are always updated on important developments, deadlines, or feedback, thereby enhancing collaboration and support.",
    },
    {
      src: require("../../assets/images/on demand access.png"),
      title: "On-Demand Access",
      content:
        "Students can access uploaded materials anytime, ensuring continuous learning beyond the classroom. This convenience empowers learners to revisit lessons at their own pace, review challenging concepts, and reinforce their understanding whenever they need, promoting lifelong learning habits.",
    },
    {
      src: require("../../assets/images/pesonalised content.png"),
      title: "Personalised Content",
      content:
        "Tailor resources to meet the specific needs of individual students or groups for more effective learning. By customising materials based on students’ strengths and challenges, educators can create a more targeted approach that enhances comprehension and supports academic growth.",
    },
  ]
 },

 {
  title: "Easy communication with students and parents ",
  smallCards:[
    {
      src: require("../../assets/images/Group Announvements.png"),
      title: "Group Announcements",
      content:
        "Share important class-wide messages or announcements without needing separate communication tools. This streamlined approach keeps everyone in the loop, ensuring that all students and parents receive the same information simultaneously, thereby reducing confusion and improving engagement with class activities.",
    },
    {
      src: require("../../assets/images/Notificatin Alert.png"),
      title: "Notification Alerts",
      content:
        "Ensure no message is missed with automatic alerts for new communications, keeping everyone informed. These notification alerts help maintain a continuous flow of information, ensuring that students and parents are always updated on important developments, deadlines, or feedback, thereby enhancing collaboration and support.",
    },
    {
      src: require("../../assets/images/Instant Messageing.png"),
      title: "Instant Messaging",
      content:
        "Quickly send updates or feedback to students and parents in real time, ensuring prompt communication. This feature fosters a strong connection between educators and families, allowing for immediate responses to questions, reminders about assignments, or important news, ultimately enhancing the learning experience.",
    },
    {
      src: require("../../assets/images/Group Announvements.png"),
      title: "Group Announcements",
      content:
        "Share important class-wide messages or announcements without needing separate communication tools. This streamlined approach keeps everyone in the loop, ensuring that all students and parents receive the same information simultaneously, thereby reducing confusion and improving engagement with class activities.",
    },
    {
      src: require("../../assets/images/Private Conversations.png"),
      title: "Private Conversations",
      content:
        "Maintain secure, private chats for discussing individual student progress with parents or students. This confidentiality encourages open dialogue about academic performance and personal challenges, allowing educators to provide tailored support and guidance while respecting students' privacy.",
    },
  ]
 },
 {
    title:"Customizable learning paths ",
    smallCards:[
      {
        src: require("../../assets/images/tailored assignments.png"),
        title: "Tailored Assignments",
        content:
          "Assign tasks and resources based on each student’s learning level and pace. This personalized approach ensures that every student receives assignments that are appropriately challenging, promoting confidence and motivation while enhancing their understanding of the material.",
      },
      {
        src: require("../../assets/images/adaptive progression.png"),
        title: "Adaptive Progression",
        content:
          "Adjust learning paths dynamically as students improve or require more support. This flexibility allows educators to respond to each student's evolving needs, ensuring they remain on track to meet their learning objectives. By modifying challenges and resources, teachers can provide the right level of support for every learner.",
      },
      {
        src: require("../../assets/images/goal setting and tracking.png"),
        title: "Goal Setting and Tracking",
        content:
          "Set personalized learning goals for students and track their progress toward achievement. By establishing clear objectives, educators can motivate students to take ownership of their learning journeys. Regularly monitoring progress helps identify successes and areas needing attention, facilitating timely interventions and support.",
      },
      {
        src: require("../../assets/images/differentiated content delivery.png"),
        title: "Differentiated Content Delivery",
        content:
          "Offer varied content formats—videos, quizzes, or readings—based on individual learning preferences. This approach caters to diverse learning styles, allowing students to engage with the material in ways that resonate most with them. By providing multiple formats, educators can enhance comprehension and retention, making learning more effective and enjoyable.",
      },
    
    ]
 },

 {
  title: "Enhanced collaboration ",
  smallCards:[
    {
      src: require("../../assets/images/Resource Sharing Hub.png"),
      title: "Resource Sharing Hub",
      content:
        "Easily exchange lesson plans, presentations, and teaching materials with other educators. This collaborative platform fosters a supportive community where teachers can share their best practices and innovative resources. By accessing a diverse array of materials, educators can enhance their lesson quality and save valuable preparation time.",
    },
    {
      src: require("../../assets/images/Collaborative Projects.png"),
      title: "Collaborative Projects",
      content:
        "Work together on cross-class or cross-subject projects, enriching student learning experiences. These collaborative initiatives encourage creativity and critical thinking by allowing students to explore topics from multiple perspectives. Teaming up with other classes or subjects not only broadens knowledge but also fosters teamwork and communication skills among students.",
    },
    {
      src: require("../../assets/images/peer Feedback.png"),
      title: "Peer Feedback",
      content:
        "Receive valuable insights and suggestions from colleagues to refine teaching strategies. Engaging in peer feedback promotes professional growth and encourages educators to reflect on their practices. Constructive critiques and shared experiences help teachers identify areas for improvement, ultimately enhancing their effectiveness in the classroom.",
    },
    {
      src: require("../../assets/images/professional learning networks.png"),
      title: "Professional Learning Networks",
      content:
        "Join discussion forums and groups to stay updated on best practices and new teaching methods. These networks provide educators with opportunities to connect, collaborate, and learn from each other. By participating in these communities, teachers can exchange ideas, access resources, and stay informed about the latest educational trends, ensuring their teaching remains relevant and impactful.",
    },
   
  ]
 },
 {
  title: "Organised assessment tools",
  smallCards:[
    {
      src: require("../../assets/images/Easy Quiz Creation.png"),
      title: "Easy Quiz Creation",
      content:
        "Design and customize quizzes in minutes with a variety of question formats. This user-friendly feature allows educators to create engaging assessments tailored to their lesson objectives. Whether using multiple-choice, true/false, or open-ended questions, teachers can quickly develop quizzes that effectively evaluate student understanding.",
    },
    {
      src: require("../../assets/images/Auto-Grading Featue.png"),
      title: "Auto-Grading Feature",
      content:
        "Automatically grade multiple-choice and objective questions, saving time on manual checking. This efficient tool eliminates the tedious task of grading, allowing educators to focus on teaching rather than paperwork. Instant grading not only streamlines the process but also provides students with quick insights into their performance.",
    },
    {
      src: require("../../assets/images/Customizable Exam Templates.png"),
      title: "Customizable Exam Templates",
      content:
        "Use pre-built templates for assignments and exams, making the setup process quick and simple. These templates save educators time and effort, enabling them to create assessments that adhere to their curriculum standards. With customizable options, teachers can easily modify content to fit their specific needs",
    },
   
  ]
 },

 {
  title: "Efficient grading and feedback",
  smallCards:[
    {
      src: require("../../assets/images/Instant Grade Calculation.png"),
      title: "Instant Grade Calculation",
      content:
        "Automate grading processes, saving time while maintaining accuracy. With instant grade calculation, teachers can quickly assess student work without manual calculations. This efficiency not only reduces the burden of grading but also ensures that scores are accurate and reflective of student performance. By streamlining this process, educators can focus more on instruction and support rather than administrative tasks.",
    },
    {
      src: require("../../assets/images/Customizable Feedback Templets.png"),
      title: "Customizable Feedback Templates",
      content:
        "Provide personalised comments using pre-set templates tailored to each student's needs. These customizable feedback templates allow teachers to offer specific and constructive comments quickly. By selecting from pre-designed templates, educators can address individual strengths and areas for improvement, making feedback more relevant and impactful. This personalised approach fosters a growth mindset and encourages student development",
    },
    {
      src: require("../../assets/images/digital rubrics for clarity.png"),
      title: "Digital Rubrics for Clarity",
      content:
        "Use clear, digital rubrics to make grading more transparent and consistent for both teachers and students. Digital rubrics provide a structured framework for evaluating student work, ensuring clarity in expectations and assessment criteria. This transparency helps students understand how they will be graded and what areas they need to improve. For teachers, it standardises the grading process, making evaluations fairer and more objective",
    },
  ]
 },
];

const StackingCardforTeacher = ({ customStyles = {} }) => {
  const stackCardsRef = useRef(null);
  const smallCardRefs = useRef([]);
  const screenType = useSelector((state) => state.website.screenType);
const screen = useScreenSizes()
  useEffect(() => {
    const stackCards = stackCardsRef.current;
    if (!stackCards) return;

    const items = stackCards.getElementsByClassName("js-stack-cards__item");
    let scrollingFn = null;
    let scrolling = false;

    const setStackCards = () => {
      const marginY = parseInt(
        getComputedStyle(stackCards).getPropertyValue("--stack-cards-gap"),
        10
      );
      stackCards.style.paddingBottom = `${marginY * (items.length - 1)}px`;

      for (let i = 0; i < items.length; i++) {
        items[i].style.transform = `translateY(${marginY * i}px)`;
      }
    };

    const stackCardsCallback = (entries) => {
      if (entries[0].isIntersecting) {
        if (scrollingFn) return;
        stackCardsInitEvent();
      } else {
        if (!scrollingFn) return;
        window.removeEventListener("scroll", scrollingFn);
        scrollingFn = null;
      }
    };

    const stackCardsInitEvent = () => {
      scrollingFn = stackCardsScrolling;
      window.addEventListener("scroll", scrollingFn);
    };

    const stackCardsScrolling = () => {
      if (scrolling) return;
      scrolling = true;
      window.requestAnimationFrame(animateStackCards);
    };

    const animateStackCards = () => {
      const marginY = parseInt(
        getComputedStyle(stackCards).getPropertyValue("--stack-cards-gap"),
        10
      );
      const top = stackCards.getBoundingClientRect().top;
      const cardStyle = getComputedStyle(items[0]);
      const cardTop = Math.floor(parseFloat(cardStyle.getPropertyValue("top")));
      const cardHeight = Math.floor(
        parseFloat(cardStyle.getPropertyValue("height"))
      );
      const windowHeight = window.innerHeight;

      if (
        cardTop - top + windowHeight - cardHeight + marginY * items.length >
        0
      ) {
        scrolling = false;
        return;
      }

      for (let i = 0; i < items.length; i++) {
        const scrollingValue = cardTop - top - i * (cardHeight + marginY);
        if (scrollingValue > 0) {
          const scaling =
            i === items.length - 1
              ? 1
              : (cardHeight - scrollingValue * 0.05) / cardHeight;
          items[i].style.transform = `translateY(${
            marginY * i
          }px) scale(${scaling})`;
        } else {
          items[i].style.transform = `translateY(${marginY * i}px)`;
        }
      }

      scrolling = false;
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        const cardIndex = Array.from(items).indexOf(entry.target);
        if (entry.isIntersecting) {
          setActiveButtonIndex(cardIndex); // Update active button index based on visible card
        }
      });
    });

    for (let i = 0; i < items.length; i++) {
      observer.observe(items[i]);
    }

    window.addEventListener("resize", setStackCards);
    return () => {
      window.removeEventListener("resize", setStackCards);
      observer.disconnect();
      window.removeEventListener("scroll", scrollingFn);
    };
  }, []);
  const [activeButtonIndex, setActiveButtonIndex] = useState(0);
 
 
  
  const defaultStyles = {
    container: {
      "--stack-cards-gap": "40px",
      "--stack-cards-item-ratio": "2/1",
     
    },
    stackCards: {
      paddingBottom: "calc(var(--stack-cards-gap) * 1)",
      // height: "80%",
    
     
    },
    cardItem: {
      position: "relative",
      position: "sticky",
      
      top: "30px",
      // height: "60%",
      paddingBottom: screenType==="smallPhone"?"calc(35vh / var(--stack-cards-item-ratio))" : screenType==="phone" ? "calc(40vh / var(--stack-cards-item-ratio))": screenType==="tablet"? "calc(20vh / var(--stack-cards-item-ratio))": screenType==="tab" ?"calc(20vh/ var(--stack-cards-item-ratio))" : screen >= 1200 ?"calc(40vh / var(--stack-cards-item-ratio))" :"",
      transformOrigin: "center top",
      backgroundColor:"#ffffff",
      borderRadius: "8px",
      boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
      marginBottom: screenType==="smallPhone" ? "130px" : screenType==="tab"?"170px": "110px",
      width: "95%",
      borderRadius:"10px",
      boxShadow: "5px 5px 5px rgba(0, 0, 0, 0.3)",
      // overflow: "hidden",
      margin:"0 auto",
      padding:"15px"
      // overflow: "hidden",
    },
    cardContent: {
      position: "relative",
      top: 0,
      left: 0,
     
      height: "100%", // Default height
      display: "flex",
      flexDirection: "column",
      // overflowX: "hidden", // Add this line
      overflow:"hidden",
            backgroundColor:"#ffffff",
        padding:"10px",
        borderRadius:"10px",
      justifyContent: "space-between",
      transition: "height 0.3s ease", 
    },
    smallCardContainer: {
      display:     "flex",
     
      width:"96%",
      overflowX: "scroll",

      // overflowX: "hidden", // Add this line
      overflowY: "auto",
      justifyContent: "space-between",
      // width: "97%",
      height: "100%",
      padding: "10px 11px",
      // whiteSpace: "nowrap",

    },
    smallCard: {
      height: screenType==="smallPhone"?"40vh": screenType==="tablet"? "30vw": screenType==="tab"? "40vw": "54vh",
      width: "20%",
      borderRadius: "8px",
      marginRight: "10px",
      flex: screenType==="smallPhone" ||screenType==="phone"?"0 0 70%"  :screenType==="tablet"? "0 0 30%": screenType==="tab" ?"0 0 32.5%": "0 0 21%",
      display: "flex",
      flexDirection: "column",
      overflowX: "hidden", // Ensure overflow is hidden
      justifyContent: "flex-start",
      overflowY: "auto",
      // overflow: "hidden",
      position: "relative",
      transition: "height 0.3s ease",
      boxShadow: "5px 5px 5px rgba(0, 0, 0, 0.3)",
    },
    smallCardImage: {
      height: "70%",
      // maxWidth: "100%",
      borderRadius: "8px 8px 0 0",
      objectFit: "cover",
      transition: "height 0.3s ease",
    },
    smallCardContent: {
      height: "auto",

      display: "flex",
      flexDirection: "column",
      textOverflow: "ellipsis",
      fontSize:  screenType==="smallPhone"?"small":screenType==="tablet" ? "1rem" : "1.1rem",
      overflowX: "hidden", // Ensure overflow is hidden
      overflowY:"scroll",
      whiteSpace: "normal", // Allow text to wrap to the next line
        backgroundColor:"#ffffff",
      backgroundColor: "#ffffff",
      rowGap: screenType==="smallPhone"? "13px": screenType==="tab"?"9px":"13px",
      borderRadius: "0 0 8px 8px",
      transition: "height 0.3s ease", // Transition for height change
      position: "absolute",
      bottom: "0",
      width: "100%",
      padding: "5px", // Add padding to give some space
      marginTop:"20px"
    },
    smallCardHover: {
      height: "80%", // Expanded height on hover
    },
    title: {
      padding: "10px", // Added padding for title
      fontSize: "1.5rem", // Adjust as needed
      color: "#333", // Title color
      flexShrink: 0, // Prevent shrinking
    },
    smallCardTitle: {
      fontSize: screenType==="smallPhone" ||screenType==="phone" ? "1.2rem":screenType==="tablet"? "1.8rem":"1.7rem", // Adjust size as needed
      color: "black", // Title color
      // padding: "5px", // Add padding for better spacing
      // textAlign: 'center', // Center the title
      flexShrink: 0, // Prevent title from shrinking
    },
    navbar: {
      display: "flex",
      // marginLeft: "10px",
      width:"100vw",
      overflowX:"scroll",
      // height:"6vh",
      justifyContent: "flexstart",
      // marginTop: "10px",
      overflowX:"scroll"
    },
    navItem: {
      marginRight: "10px",
      cursor: "pointer",
      // padding: screenType==="smallPhone"? "": "5px 10px",
      color: "grey",
     
      borderRadius: "4px",
      fontSize: screenType==="smallPhone"||screenType==="phone"?"0.9rem": screenType==="tablet"?"1rem":"",
      // textDecoration: 'underline',
    },
    activeNavItem: {
     
      textDecoration: "underline",
      fontWeight: "bold",
      color: "black",
     
      color: "black", // Change color for active state if needed
    },
    cardtitle:{
      margin:"0px",
      fontSize: screenType==="tablet"?"1.7rem": screenType==="tab"? "1.6rem": screenType==="smallPhone"||screenType==="phone" ?"1.7rem":"2rem",
      fontFamily:""
    }
  };
const {t} = useTranslation();
  return (
    <>
    

    <div
      style={{ ...defaultStyles.container, ...customStyles.container }}
      ref={stackCardsRef}
    >
    
      <div style={defaultStyles.stackCards}>
      {cardData.map((card, largeCardIndex) => (
      // Conditionally render based on active index
              <div
                style={{ ...defaultStyles.cardItem, ...card.largeCardStyle }}
                key={largeCardIndex}
                className="js-stack-cards__item"
              >
                <div style={defaultStyles.cardContent}>
                  <p style={defaultStyles.cardtitle}>{t(card.title)}</p>
                
                  <div style={defaultStyles.smallCardContainer}>
                    {card.smallCards.map((smallCard, smallCardIndex) => (
                      <div
                        key={smallCardIndex}
                        ref={(el) => {
                          if (!smallCardRefs.current[largeCardIndex]) {
                            smallCardRefs.current[largeCardIndex] = [];
                          }
                          smallCardRefs.current[largeCardIndex][smallCardIndex] = el;
                        }}
                        style={defaultStyles.smallCard}
                        className="small-card"
                        onMouseEnter={(e) => {
                          const content = e.currentTarget.querySelector(".small-card-content");
                          content.style.height = "90%"; // Expand content height on hover
                        }}
                        onMouseLeave={(e) => {
                          const content = e.currentTarget.querySelector(".small-card-content");
                          content.style.height = "30%"; // Reset to original height
                        }}
                      >
                        <img
                          src={smallCard.src}
                          alt={smallCard.alt}
                          style={defaultStyles.smallCardImage}
                          className="small-card-image"
                        />
                        <div
                          style={{
                            ...defaultStyles.smallCardContent,
                            height: "30%", // Initial height
                          }}
                          className="small-card-content"
                        >
                          <div style={defaultStyles.smallCardTitle}>
                            <p style={{ display:"flex",  marginLeft:"20px"  }}>{t(smallCard.title)}</p>
                          </div>
                          <div
                            style={{
                              height: "100%",
                              height: "auto", // Set to auto to allow it to expand
                              flex: "1",
                              padding:"20px",
                            }}
                          >
                            {t(smallCard.content)}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )
          )}
        </div>
      </div>
    </>
  );
};

export default StackingCardforTeacher;