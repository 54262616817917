import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  notifications: [], // Initialize as an empty array
};

const NotificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    addNotification: (state, action) => {
      if (!Array.isArray(state.notifications)) {
        state.notifications = []; // Ensure notifications is always an array
      }
      state.notifications.push(action.payload); // Add notification
    },
    removeNotification: (state, action) => {
      state.notifications = state.notifications.filter(
        (notification) => notification.id !== action.payload
      );
    },
    clearNotifications: (state) => {
      state.notifications = []; // Clear all notifications
    },
  },
});

export const { addNotification, removeNotification, clearNotifications } = NotificationSlice.actions;
export default NotificationSlice.reducer;
