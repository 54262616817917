import React, { useState, useEffect } from 'react';
import {  getToken, onMessage,} from 'firebase/messaging';
import { messaging } from '../../firebase';
import NotificationBox from '../test/Notification';
import { useDispatch, useSelector } from 'react-redux';
import { addNotification, removeNotification } from '../../reduxStore/slices/Notification';
// Notification component to display styled notifications
const Notification = ({ title, body, onDismiss , img}) => {
    const notifications = useSelector((state) => state.notification.notifications);
 console.log(notifications)
    return (
        <NotificationBox title={title} body={body} onDismiss={onDismiss} imageUrl={img}/>
    );
};

const NotificationContainer = () => {
  const [notification, setNotification] = useState(null);
  const [visible, setVisible] = useState(false);
  const dispatch = useDispatch()
 
 console.log()
  useEffect(() => {
    requestPermission();
  }, []); // Request permission on component mount

  useEffect(() => {
    const unsubscribe = onMessage(messaging, (payload) => {
      console.log('Message received. ', payload);
      displayNotification(payload);
    });

    return () => unsubscribe(); // Clean up the listener on unmount
  }, []); // This runs only once when the component mounts

  async function requestPermission() {
    console.log('Requesting permission...');
    try {
      const permission = await  window.Notification.requestPermission();
      if (permission === 'granted') {
          console.log('Notification permission granted.');
          const token = await getToken(messaging, {
          vapidKey: "BOaQ482qDKa3uJWM5SQUVpRAOAh0-MSmmKYUdugqArdyluZe1vzoO8789D1kRFCtXxo8sVm72Weqr9drJpi8EYU"
        });
        if (token) {
            console.log("FCM Token:", token);
            // Send token to server or store it for future use
        } else {
          console.log("No registration token available.");
        }
      } else {
        console.log('Unable to get permission to notify.');
      }
    } catch (error) {
      console.error("Error requesting notification permission: ", error);
    }
  }

  function displayNotification(payload) {
    if (!payload?.notification) {
      console.error('Invalid payload:', payload);
      return;
    }
  
    const { title, body, image } = payload.notification;
  
    // Generate a unique ID for each notification
    const id = Date.now();
  
    // Dispatch the notification to Redux
    dispatch(
      addNotification({
        id,
        title,
        body,
        image,
      })
    );
  
    // Set local state for visibility
    setNotification({ id, title, body, image });
    setVisible(true);
  
    // setTimeout(() => {
    //   setVisible(false);
    //   dispatch(removeNotification(id)); // Automatically remove after timeout
    // }, 5000);
  }
  
  // Dismiss the notification
  const handleDismiss = () => {
    setVisible(false);
  };

  return (
    <>
      {visible && notification && (
        <Notification
          title={notification.title}
          body={notification.body}
          onDismiss={handleDismiss}
          img = {notification.image}
        //   video = {notification.video}
        />
      )}
    </>
  );
};

// Notification box styling
const styles = {
  notificationBox: {
    position: 'fixed',
    top: '20px',
    right: '20px',
    backgroundColor: '#f8d7da',
    color: '#721c24',
    padding: '20px',
    border: '1px solid #f5c6cb',
    borderRadius: '10px',
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
    zIndex: 9999,
    textAlign: 'center',
    width: '300px',
    animation: 'fadeIn 0.5s',
  },
  dismissButton: {
    marginTop: '10px',
    backgroundColor: '#f5c6cb',
    color: '#721c24',
    border: 'none',
    padding: '10px 15px',
    borderRadius: '5px',
    cursor: 'pointer',
  }
};

export default NotificationContainer;
