import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  token: null,
  user: null,
  sidebar :false,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setToken: (state, action) => {

      state.token = action.payload;
    },
    setSideBar: (state, action) => {
      state.sidebar = action.payload; // Directly assign the boolean
  },
    setUser: (state, action) => {
      state.user = action.payload.user; // Keeps user data
      state.token = action.payload.token; // Updates token
    },
  
    clearAuth: (state) => {
      state.token = null;
      state.user = null;
    },
  },
});

export const { setToken, setUser, clearAuth, setSideBar  } = authSlice.actions;
export const selectUser = (state) => state.auth.user; // Selector for user
export const selectToken = (state) => state.auth.token; // Selector for token
export const sidebar = (state)=>  state.auth.sidebar;

export default authSlice.reducer;
