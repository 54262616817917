import React from 'react';
import { useTranslation } from 'react-i18next';

const RefundPolicy = () => {
  const style = {
    container: {
        width: "90%",
        // maxWidth: '800px',
        margin: "40px auto",
        padding: "20px 40px",
        backgroundColor: "#f7f9fc",
        borderRadius: "10px",
        boxShadow: "0 6px 12px rgba(0, 0, 0, 0.1)",
        fontFamily: '"Arial", sans-serif',
        color: "#333",
        lineHeight: "1.8",
        letterSpacing: "0.5px",
      },
    heading: {
      fontSize: '30px',
      fontWeight: 'bold',
      color: '#333',
      textAlign: 'center',
      marginBottom: '20px',
    },
    subHeading: {
      fontSize: '22px',
      fontWeight: 'bold',
      marginTop: '30px',
      color: '#444',
    },
    section: {
      marginTop: '15px',
    },
    paragraph: {
      marginBottom: '20px',
      fontSize: '16px',
      color: '#555',
    },
    list: {
      marginLeft: '30px',
    },
    listItem: {
      marginBottom: '10px',
      fontSize: '16px',
      color: '#555',
    },
    note: {
        backgroundColor: "#eaf3ff",
        borderLeft: "5px solid #1a73e8",
        padding: "15px",
        margin: "20px 0",
        fontStyle: "italic",
        color: "black",
      },
    footer: {
      marginTop: '30px',
      textAlign: 'center',
      color: '#555',
      fontSize: '14px',
    },
    contactLink: {
      color: '#007bff',
      textDecoration: 'none',
    },
    bold: {
      fontWeight: 'bold',
    },
  
        contact: {
            fontWeight: "bold",
            color: "black",
            textDecoration: "none",
            display: "inline-block",
            marginTop: "20px",
            padding: "5px 10px",
            borderRadius: "5px",
            border: "1px solid #1a73e8",
            transition: "background-color 0.3s, color 0.3s",
          },
    
  };

 const {t} = useTranslation();

  return (
    <div style={style.container}>
      <h1 style={style.heading}>{t('Refund Policy')}</h1>
  
      <div style={style.section}>
        <p style={style.paragraph}>
          {t('Siyaram Nextech Pvt. Ltd. is committed to providing innovative learning solutions for schools and individuals. We are proud to introduce StudyBuddy, a comprehensive Learning Management System (LMS) and learning platform designed to empower educators and students of all ages.')}
        </p>
        <p style={style.paragraph}>
          {t('Your satisfaction is our priority. This refund policy outlines the conditions under which we offer refunds for purchases made within the StudyBuddy app. We strive to be fair and transparent in our practices, ensuring a positive experience for all our users.')}
        </p>
      </div>
  
      <div style={style.section}>
        <h2 style={style.subHeading}>{t('Products Covered:')}</h2>
        <ul style={style.list}>
          <li style={style.listItem}>
            <span style={style.bold}>{t('Institute’s LMS Subscriptions:')}</span> {t('This covers subscriptions purchased by educational institutions (schools, colleges, universities, coaching centers) for access to the StudyBuddy LMS platform. These subscriptions allow institutions to create and manage accounts for students, teachers, and administrators within their organization')}
          </li>
          <li style={style.listItem}>
            <span style={style.bold}>{t('Individual Subscriptions:')}</span> {t('This covers subscriptions purchased by individual users, including students, teachers, and parents. Individual subscriptions provide access to various features within the StudyBuddy app, such as enrolled courses, personalized learning resources, and progress tracking.')}
          </li>
        </ul>
        <div style={style.note}>
          {t('Note: This policy applies to all purchases made directly through the StudyBuddy app, excluding any processing fees and in-app purchases (if applicable). For purchases made through third-party app stores, please refer to their individual refund policies.')}
        </div>
      </div>
  
      <div style={style.section}>
        <h2 style={style.subHeading}>{t('Non-Refundable Items:')}</h2>
        <p>
          {t('There are certain items and services within the StudyBuddy app that are not eligible for refunds. These include:')}
        </p>
        <ul style={style.list}>
          <li style={style.listItem}><strong>{t('School Subscriptions:')}</strong> {t('Subscriptions purchased by educational institutions (schools, colleges, etc.) are not refundable. This includes the 15-day free trial period offered to schools.')}</li>
          <li style={style.listItem}><strong>{t('Individual Subscriptions:')}</strong> {t('Subscriptions purchased by students, teachers, and parents for individual use are also not refundable.')}</li>
          <li style={style.listItem}><strong>{t('Processing Fees:')}</strong> {t('Processing fees associated with your initial purchase (e.g., credit card fees) are non-refundable.')}</li>
        </ul>
      </div>
  
      <div style={style.section}>
        <h2 style={style.subHeading}>{t('Refundable Items (Limited Cases):')}</h2>
        <p>{t('In limited circumstances, we may offer refunds for the following:')}</p>
        <ul style={style.list}>
          <li style={style.listItem}>
            <span style={style.bold}>{t('Failed Payments:')}</span> {t('If your payment attempt for a School Subscription or Individual Subscription fails due to technical reasons (e.g., insufficient funds, network issues), the associated funds will be automatically returned to your original payment method.')}
          </li>
        </ul>
      </div>
  
      <div style={style.section}>
        <h2 style={style.subHeading}>{t('School Subscriptions and Individual Access:')}</h2>
        <ul style={style.list}>
          <li style={style.listItem}>{t('Non-Refundable for All Users: Regardless of whether a school has a StudyBuddy subscription, individual subscriptions purchased by students, teachers, and parents are not refundable.')}</li>
          <li style={style.listItem}>{t('School Subscription Trial Period: The 15-day free trial offered to schools is also non-refundable.')}</li>
        </ul>
      </div>
  
      <div style={style.section}>
        <h2 style={style.subHeading}>{t('Refund Request Process (Not Applicable):')}</h2>
        <p style={style.paragraph}>
          {t('Due to the non-refundable nature of School Subscriptions and Individual Subscriptions, a formal refund request process is not applicable. However, you can contact us if you have any questions or require clarification on this policy.')}
        </p>
      </div>
  
      <div style={style.section}>
        <h2 style={style.subHeading}>{t('Refund Processing (Not Applicable):')}</h2>
        <p style={style.paragraph}>
          {t('Since refunds are not applicable for School Subscriptions and Individual Subscriptions, this section is not relevant.')}
        </p>
      </div>
  
      <div style={style.section}>
        <h2 style={style.subHeading}>{t('Changes to this Policy:')}</h2>
        <p style={style.paragraph}>
          {t('Siyaram Nextech Pvt. Ltd. understands that user needs and the educational technology landscape can evolve over time. To ensure we continue to provide a fair and effective refund policy for the StudyBuddy app, we reserve the right to modify this policy at any time. However, we are committed to transparent communication and will make every effort to clearly inform users about any changes implemented.')}
        </p>
        <p>
          {t('Here’s how we will communicate changes to the StudyBuddy app refund policy:')}
        </p>
        <ul style={style.list}>
          <li style={style.listItem}>{t('Posting Updates: The most up-to-date version of the refund policy will always be readily available within the StudyBuddy app. We will promptly upload any revisions to this location, ensuring users have easy access to the latest information.')}</li>
          <li style={style.listItem}>{t('Email Notifications: For significant changes that might substantially impact user expectations, we may send email notifications to registered StudyBuddy users. These emails will clearly outline the nature of the modifications and the effective date of the revised policy.')}</li>
          <li style={style.listItem}>{t('Version Control and Transparency: We will maintain a clear version history of the refund policy within the app. This allows users to see past versions and understand how the policy has evolved over time. The date of the last revision will always be displayed at the bottom of the document for your reference.')}</li>
        </ul>
      </div>
  
      <div style={style.section}>
        <h2 style={style.subHeading}>{t('Why We Update the Policy:')}</h2>
        <p>
          {t('There are several reasons why we might need to update the StudyBuddy app refund policy. These may include:')}
        </p>
        <ul style={style.list}>
          <li style={style.listItem}>{t('Legal and Regulatory Changes: As laws and regulations governing online transactions and consumer protection evolve, we may need to adapt our refund policy to comply with the latest legal requirements.')}</li>
          <li style={style.listItem}>{t('Evolving Payment Methods: The landscape of online payment options is constantly changing. We may need to update the policy to address new payment methods or address potential issues related to specific payment processors.')}</li>
          <li style={style.listItem}>{t('Improved User Experience: We are constantly striving to improve the user experience with StudyBuddy. This might involve changes to our subscription models, free trial periods, or access levels. The refund policy will be updated to reflect these changes and ensure clarity for users.')}</li>
          <li style={style.listItem}>{t('Addressing User Feedback: We value your feedback! If we receive consistent user concerns about specific aspects of the refund policy, we may review and revise the policy to address those concerns and create a more user-friendly experience.')}</li>
        </ul>
      </div>
      <p>
        {t('We encourage you to periodically review the StudyBuddy app refund policy to stay informed of any updates. By doing so, you can ensure you are fully aware of your rights and responsibilities regarding refunds within the platform.')}
      </p>
      <div style={style.section}>
        <h2 style={style.subHeading}>{t('Contact Us:')}</h2>
        <p style={style.paragraph}>
          {t('If you have any questions about this Privacy Policy, please contact us at support')}
        </p>
        <a href="mailto:Siyaramgroup.nextech@gmail.com" style={style.contact}>
          Siyaramgroup.nextech@gmail.com
        </a>
      </div>
    </div>
  );
};

export default RefundPolicy;
