import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  headerHeight: "", // Default height
  reach: false, // Add reach to initial state
  theme: "default", // Default theme
  screenType: "desktop",
  loading1: true,
  loading2:true,
  loading:true,
  TitlebarHeight:"",
  headerSize:"small",
  treeClick:false,
  animate:"false"
};
const websiteSlice = createSlice({
  name: "wesbite",
  initialState,
  reducers: {
    setHeaderHeight: (state, action) => {
      state.headerHeight = action.payload;
    },

    setLoading1: (state, action) => {
      state.loading1 = action.payload;
    },
    setLoading2: (state, action) => {
      state.loading2 = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setReach: (state, action) => {
      state.reach = action.payload; // New action to set reach
    },
    setTheme: (state, action) => {
      state.theme = action.payload; // New action to set theme
    },
    setScreenType: (state, action) => {
      state.screenType = action.payload; // Action to update screen type
    },
    setTitlebarHeight: (state, action) => {
      state.TitlebarHeight = action.payload; // Action to update screen type
    },
    setHeaderSize: (state, action) => {
      state.headerSize = action.payload; // Action to update screen type
    },
    setAnimate: (state, action) => {
      state.animate = action.payload; // Action to update screen type
    },
    setTreeClick: (state, action) => {
      state.treeClick = action.payload; // Action to update screen type
    },
  },
});
// Exporting actions
export const { setHeaderHeight, setReach, setLoading1,  setLoading2,setTheme,setLoading, setScreenType , setTitlebarHeight, setHeaderSize, setAnimate , setTreeClick } =
websiteSlice.actions;

export default websiteSlice.reducer;
