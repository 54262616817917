import React, { useEffect, useState, useRef } from "react";
import { useLocation } from "react-router-dom"; // Ensure this is imported if using location.state
import Careerscard from "./Careerscard";
import ContactUs from "./contact";
import App from "./GetStarted";

const Info = () => {
  const location = useLocation();
  const [activeComponent, setActiveComponent] = useState(null);
 
console.log(location.state)
  useEffect(() => {
    if (location.state) {
      const { section } = location.state;

      if (section === 'CONTACT US') {
        setTimeout(() => { 
            setActiveComponent('contact');
        }, 200 )
      } else if (section === 'REFER') {
        setTimeout(() => { 
            setActiveComponent('refer');
        }, 200 )      } 
        else if (section === 'CAREER') {
            setTimeout(() => { 
                setActiveComponent('career');
            }, 200 )
      }  

      else if (section === 'GET STARTED') {
        setTimeout(() => { 
            setActiveComponent('getStarted');
        }, 200 )
  } 
    
    }
  }, [location]);

  return (
    <div>
      {activeComponent === 'contact' && <ContactUs />}
      {/* {activeComponent === 'refer' && <p>dfegrfhn </p>} */}
      {activeComponent === 'career' && <Careerscard/>}
      {activeComponent === 'getStarted' && <App/>}

    
    </div>
  );
};

export default Info;
