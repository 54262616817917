import React from "react";

// props -  variant - green, orange, red, grey, blue 
//         type - selection 
//         title - 
//         func - 
//         ? width -
const App = (props) => {

    const getButtonColor = () => {
        if (props.type === 'selection') {
            if (!props.isSelected) {
                return '#BFBFBF';
            }
        }
        switch (props.variant) {
            case 'green':
                return "#1CAC1F";
            case 'orange':
                return '#EB9431';
            case 'grey':
                return '#BFBFBF';
            case 'red':
                return '#E00202';
            case 'yellow':
                return '#E8C000';
            default:
                return '#295FF4';
        }
    };

    const buttonColor = getButtonColor();

    return (
        <button 
            disabled={props.disabled ? props.disabled : null} 
            onClick={props.func} 
            style={{ ...styles.buttonContainer, backgroundColor: buttonColor, width: props.width ? props.width : 125 }}
        >
            <span style={styles.buttonText}>{props.title}</span>
        </button>
    );
};

export default App;

const styles = {
    buttonContainer: {
        borderRadius: '10px',
        width: '125px',
        borderWidth: '2px',
        justifyContent: 'center',
        // alignItems: 'center',
        borderColor: 'white',
        padding: '7px 20px',
        display: 'inline-flex',
        cursor: 'pointer',
    },
    buttonText: {
        color: 'white',
        fontFamily: 'MarkerFelt, sans-serif',
        fontSize: '15px',
    }
};
