// utils/useScreenSizes.js
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setScreenType } from '../reduxStore/slices/WebSiteData';

const useScreenSizes = () => {
  const [screenSize, setScreenSize] = useState(window.innerWidth);
  const dispatch = useDispatch();

  const updateScreenType = (size) => {
    if (size >= 1200) {
      dispatch(setScreenType('desktop'));
    } else if (size >= 1000) {
      dispatch(setScreenType('tablet'));
    } else if (size >= 650) {
      dispatch(setScreenType('tab'));
    } else if (size >= 350) {
      dispatch(setScreenType('phone'));
    } else {
      dispatch(setScreenType('smallPhone'));
    }
  };

  useEffect(() => {
    const handleResize = () => {
      const newSize = window.innerWidth;
      setScreenSize(newSize);
      updateScreenType(newSize);
    };

    window.addEventListener('resize', handleResize);
    updateScreenType(screenSize); // Set initial screen type

    return () => window.removeEventListener('resize', handleResize);
  }, [screenSize, dispatch , updateScreenType]);

  return screenSize;
};

export default useScreenSizes;
