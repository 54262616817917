import React, { useEffect, useRef } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import About from '../About/About';
import LoginPage from '../loginPage/loginPage';
import HomePage from '../Home/HomePage';
import Logout from '../loginPage/logout';
import Solutios from '../Solution/Solutions';
import FAQQuestions from '../FAQ/FAQ';
import VisionContainer from '../Vision/Vision';


import ContactUs from '../../Screens/Info/contact';
import Info from '../Info/info';
import NotFound from '../../Components/test/Error';
import LMS from '../LMS/LMS';
import PrivacyPolicy from '../Policies/PrivacyPolicy';
import RefundPolicy from '../Policies/RefundPolicy';
import TermsOfUse from '../Policies/TermOfUse';

import JobRequirementForm from '../Info/JobRequirementForm';
import App from '../Info/GetStarted';
import BlogScreen from '../Blog/blogScreen';
import themes from '../../config/theme';
const MainContentArea = () => {
  const location = useLocation();
  const token= useSelector((state) => state.auth.token);
 
const currentTheme = useSelector((state) => state.website.theme);
const selectedTheme = themes[currentTheme] || themes.default; // Use selected theme
  const containersRef = useRef(null);
  const styles = {
    MainContentArea: {
      minHeight: "100vh",
      // display:"flex",
      backgroundColor: selectedTheme.soilColor,
      width: "100vw",
  
         overflow: location.pathname==="/LMS" ? "hidden" :""
     
    
    },
  };
  useEffect(() => {
    if (containersRef.current) {
      containersRef.current.scrollTo(0, 0);
    }
  }, [location.pathname]); 
  useEffect(() => {
    if (containersRef.current) {
      containersRef.current.scrollTo(0, 0);
    }
  }, [location.state]); 
  return (
    <div  ref={containersRef} style={styles.MainContentArea}>
      <Routes>
        <Route path='/' element={<HomePage />} />
        <Route path='/logout' element={<Logout />} />
        <Route path="/about" element={<About />} />
        <Route path="/solution" element={<Solutios/>} />
        <Route path="/blogs" element={<BlogScreen/>} />
        <Route path="/FAQ" element={<FAQQuestions/>} />
        <Route path="/vision" element={<VisionContainer/>} />
        <Route path="/LMS" element={token ? <LMS/> : <LoginPage />} />
        <Route path='/forStudent' element={<HomePage/>} />
        <Route path='/forParent' element={<HomePage/>} />
        <Route path='/forTeacher' element={<HomePage/>} />
        <Route path='/forInstitute' element={<HomePage/>} />
        <Route path='/info' element={<Info/>} />
        <Route path='/jobApplication' element={<JobRequirementForm/>} />
        <Route path='/privacyPolicy' element={<PrivacyPolicy/>} />
        <Route path='/refundPolicy' element={<RefundPolicy/>} />
        <Route path='/termofuse' element={<TermsOfUse/>} />
        <Route path='/contactus' element={<ContactUs/>} />
        <Route path='/LMS' element={<LMS/>} />
        <Route path="*" element={<NotFound/>} />
        <Route path='/Login' element={<LoginPage/>} />
        <Route path="/getStarted" element={<App/>} />
      </Routes>
    </div>
  );
};

export default MainContentArea;
