import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import blogIcon from "../../assets/Icons/blog.png";
import visionIcon from "../../assets/Icons/vision_18030458.png";
import solutionIcon from "../../assets/Icons/problem-solving_11689154.png";
import faqIcon from "../../assets/Icons/faq_5623586.png";
import loginIcon from "../../assets/Icons/login_11010806.png";
import CloseIcon from '@mui/icons-material/Close';

import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation
import { useTranslation } from 'react-i18next';

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  justifyContent: 'flex-end',
  height: '30px',
}));
const menuItems = [
  { text: "Blogs", path: "/blogs", icon: blogIcon },
  { text: "Vision", path: "/vision", icon: visionIcon },
  { text: "Solution", path: "/solution", icon: solutionIcon },
  { text: "FAQ", path: "/FAQ", icon: faqIcon },
  { text: "Login", path: "/LMS", icon: loginIcon },
];
export default function SidebarMobile({
  drawerWidth = 180,
  
}) {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const reach = useSelector((state) => state.website.reach);
  const navigate = useNavigate(); // Hook for navigation
  
  const headerHeight = useSelector((state) => state.website.headerHeight);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  
  const handleDrawerClose = () => {
    setOpen(false);
    
  };
  const {t} = useTranslation();
  
  const handleNavigate = (path) => {
    navigate(path); // Navigate to the specified path
    setOpen(false); // Optionally close the drawer after navigating
  };
React.useEffect(()=>{
  reach?   setOpen(false) :setOpen(false);;
},[reach])
React.useEffect(() => {
  const handleClickOutside = (event) => {
    const screenWidth = window.innerWidth;
    const clickX = event.clientX;

    // If the drawer is open and the click is outside the drawer's width
    if (open && clickX < screenWidth - drawerWidth) {
      setOpen(false);
    }
  };

  // Add event listener for mousedown
  document.addEventListener('mousedown', handleClickOutside);

  // Clean up event listener when the component is unmounted
  return () => {
    document.removeEventListener('mousedown', handleClickOutside);
  };
}, [open, drawerWidth]);
  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <IconButton
        color="inherit"
        aria-label="open drawer"
        onClick={open ? handleDrawerClose : handleDrawerOpen}
        edge="start"
        sx={{
          position: 'absolute',
          display: reach ? "" : "none",
          right: '20px',
          top: '20px',
        }}
      >
      
     {open ? <CloseIcon/> : <MenuIcon />} 
     
      </IconButton>

      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
            marginTop: headerHeight,
           
          },
        }}
        variant="persistent"
        anchor="right"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {/* {theme.direction === 'ltr' ? <ChevronRightIcon/> : <ChevronRightIcon />} */}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List style={{ border:"none"}}>
          {menuItems.map((item, index) => (
            <ListItem key={item.text} disablePadding>
              <div
                onClick={() => handleNavigate(item.path)} // Navigate to respective path
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  padding: '10px',
                  cursor: 'pointer',
                  width: '100%',
                }}
              >
                <ListItemIcon>
                <img
                    src={item.icon} // Use icon from the menuItems array
                    alt={item.text}
                    style={{ height: "25px", width: "25px" }}
                  />
                </ListItemIcon>
                <ListItemText primary={t(item.text)} />
              </div>
            </ListItem>
          ))}
        </List>
        <Divider />
      </Drawer>
    </Box>
  );
}
