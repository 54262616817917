import React, { useState, useEffect, useRef } from "react";
import { doc, getDoc } from "firebase/firestore";
import { firestore } from "../../firebase"; // Adjust the path to your firebase configuration

// Styles (keeping the original styles)
const styles = {
  container: {
    width: "95%",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    // flexDirection:"column",
    alignItems: "center",
    gap: "20px",
    
    margin: "2vw auto",
  },
  blogsHeader: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin:"20vh"
    // padding: '20px 0',
    // backgroundColor: '#f8f9fa', // light background color
    // boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // subtle shadow for depth
    // borderRadius: '8px',
  },
  blogsText: {
    fontSize: "2rem", // larger font size
    fontWeight: "bold",
    color: "#333",
    textAlign: "center",
    letterSpacing: "2px", // slight letter spacing for modern touch
  },
  post: {
    width: "330px",
    height: "500px",
    display: "flex",
    borderRadius: "10px",
    overflow: "hidden",
    flexDirection: "column",
    backgroundColor: "white",
    position: "relative",
    transition: "transform 0.2s",
  },
  headerPost: {
    width: "100%",
    // height :  "40%",
    background: "#ddd",
    position: "absolute",
    top: 0,
    transition: "margin-top 0.2s",
  },
  headerPostImage: {
    width: "100%",
    height: "100%",
    transition: "transform 0.6s",
  },
  bodyPost: {
    width: "100%",
    height: "60%",
    background: "#fff",
    position: "absolute",
    bottom: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    transition: "height 0.2s",
    cursor: "pointer",
  },
  postContent: {
    width: "80%",
    height: "80%",
    background: "#fff",
    position: "relative",
  },
  title: {
    fontSize: "15px",
    fontWeight: "bold",
  },
  paragraph: {
    fontSize: "14px",
    fontWeight: "normal",
  },
  containerInfos: {
    width: "100%",
    display: "flex",
   
    justifyContent: "space-between",
    position: "absolute",
    borderTop: "1px solid rgba(0, 0, 0, 0.2)",
    paddingTop: "25px",
  },
  postedBy: {
    display: "flex",
    flexDirection: "column",
    textTransform: "uppercase",
    letterSpacing: "1px",
    fontSize: "12px",
  },
  containerTags: {
    display: "flex",
    flexDirection: "column",
  },
  span: {
    fontSize: "12px",
    textTransform: "uppercase",
    opacity: 0.5,
    letterSpacing: "1px",
    fontWeight: "bold",
  },
  tagsList: {
    display: "flex",
  },
  tagItem: {
    fontSize: "12px",
    letterSpacing: "2px",
    listStyle: "none",
    marginLeft: "8px",
    textTransform: "uppercase",
    position: "relative",
    zIndex: 1,
    display: "flex",
    justifyContent: "center",
    cursor: "pointer",
  },
  detailContainer: {
    maxWidth: "800px",
    //  marginTop:"30px",
  
    padding: "20px",
    backgroundColor: "#fff",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    borderRadius: "8px",
    fontFamily: "Arial, sans-serif",
  },
  backButton: {
    fontSize: "16px",
    color: "#007BFF",
    border: "none",
    backgroundColor: "transparent",
    cursor: "pointer",
    marginBottom: "20px",
    display: "inline-flex",
    alignItems: "center",
  },
  detailImage: {
    width: "100%",
    height: "auto",
    borderRadius: "8px",
    marginBottom: "20px",
  },
  detailTitle: {
    fontSize: "2rem",
    fontWeight: "bold",
    color: "#333",
    marginBottom: "20px",
  },
  detailContent: {
    fontSize: "1rem",
    lineHeight: "1.6",
    color: "#555",
  },
  categoryContainer: {
    marginTop: "20px",
    fontSize: "1.1rem",
  },
  categoryLabel: {
    fontWeight: "bold",
    color: "#333",
  },
  categoryText: {
    fontStyle: "italic",
    color: "#007BFF",
  },
 
};

// BlogDetailScreen Component
const BlogDetailScreen = ({ post, onBack }) => {
const detailRef = useRef(null);
  useEffect(() => {
    if (detailRef.current) {
      detailRef.current.scrollIntoView({ top: "-199px", behavior: "smooth" });
    }
  }, []);


  return (
    <div style={styles.detailContainer} ref={detailRef}>
      <button style={styles.backButton} onClick={onBack}>
        Back to Blogs
      </button>

      <img src={post.imagelink} alt={post.title} style={styles.detailImage} />

      <div style={{ marginTop: "10px" }}>
        <strong>Blog Category</strong> {post.BlogCategory}
      </div>
      <h1 style={styles.detailTitle}>{post.BlogTopic}</h1>

      <div style={styles.detailContent}>
        <p>{post.BlogContent}</p>

   
      </div>
    </div>
  );
};

// PostCard Component
const PostCard = ({ post, onCardClick }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <>
      <div
        style={{ ...styles.post }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onClick={() => onCardClick(post)}
      >
        <div
          style={{
            ...styles.headerPost,
            height: isHovered ? "40%" : "40%",
            transition: "height 0.3s ease-in",
          }}
        >
          <img
            src={post.imagelink}
            alt={post.title}
            style={{
              ...styles.headerPostImage,
              transform: isHovered ? "scale(1.3)" : "scale(1)", // Scale effect
              transition: "transform 0.3s ease-in-out", // Smooth scaling transition
            }}
          />
        </div>

        <div
          style={{
            ...styles.bodyPost,
            // height: isHovered ? "50%" : "60%",
            height: "60%",
            transform: isHovered ? "translateY(30px)" : "translateY(0)", // Conditional transform
            transition: "transform 0.2s ease-in-out", // Smooth transition for the effect
          }}
        >
          <div style={styles.postContent}>
            <p style={styles.title}>{post.BlogTopic}</p>
            <p
              style={{
                ...styles.paragraph,
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                overflow: "hidden",
                WebkitLineClamp: isExpanded ? "unset" : 3,
              }}
            >
              {post.BlogContent}
            </p>
            {!isExpanded &&
              post.BlogContent.length > 200 && ( // Check if the content is longer
                <button
                  style={{
                    color: "#FF6347",
                    background: "none",
                    border: "none",
                    cursor: "pointer",
                    opacity: isHovered ? 1 : 0.5, // Show the button only on hover
                    transition: "opacity 0.3s ease-in-out",
                  }}
                  onClick={() => onCardClick(post)}
                >
                  See More
                </button>
              )}

            <div style={styles.containerInfos}>
            

              <div style={styles.containerTags}>
                <span style={styles.span}>blogCategory</span>
                <div style={styles.tagsList}>
                  <ul>
                    <li style={styles.tagItem}>{post.BlogCategory}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

// Main Blogs Component
const Blogs = ({onDetailToggle}) => {
  const [selectedPost, setSelectedPost] = useState(null);
  const [posts, setPosts] = useState([]); // State to store fetched blog data

  // Fetch data from Firestore on component mount
  useEffect(() => {
    const fetchBlogs = async () => {
      const docRef = doc(firestore, "blogData", "ABC"); // The document ID where the data is stored
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const blogData = docSnap.data();
        setPosts(blogData.blogs); // Set the 'blogs' array from Firestore
      } else {
      }
    };

    fetchBlogs();
  }, []); // Empty dependency array ensures the effect runs once on mount
  
  // Handle post selection
  const handlePostClick = (post) => {
    setSelectedPost(post);
    onDetailToggle(true);
  };

  // Handle back to blog list
  const handleBackToBlogList = () => {
    setSelectedPost(null);
    onDetailToggle(false);
  };

  // Render blog list or detail view
  if (selectedPost) {
    return (
      <BlogDetailScreen  post={selectedPost} onBack={handleBackToBlogList} />
    );
  }

  return (
    <>
     
      <div style={styles.container}>
        {posts.map((post) => (
          <PostCard key={post.id} post={post} onCardClick={handlePostClick} />
        ))}
      </div>
    </>
  );
};

export default Blogs;
