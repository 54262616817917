import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
// import App from './Components/searching/Stories';
import App from "./App"
import AlgoliaSearchComponent from './Components/searching/searchuser';
// import ReferenceSearchArray from './Components/searching/ReferenceArraySearch';
const root = ReactDOM.createRoot(document.getElementById('root')); 



const documentIds = [ 'LMS001', 'LMS002', 'LMS003', 'LMS004', 'LMS005'];
root.render(
    
    <>


<App/>

 </>
  
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
