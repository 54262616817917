import React, { useState } from 'react';
import {algoliasearch }from 'algoliasearch'; // Correct import for algoliasearch

const searchClient = algoliasearch('7BER79SCQK', '51f6b19d6579616e0bad6f843d293325');

const AlgoliaSearchComponent = ({ documentIds }) => {
  const [query, setQuery] = useState('');
  const [results, setResults] = useState([]);
  const [error, setError] = useState(null);

  const handleSearch = async () => {
    try {
      const filters = documentIds.map(id => `objectID:${id}`).join(' OR ');
      // Construct filters to limit search to specified document IDs
      console.log(filters)
      // Initialize the index and perform the search
      const searchResults = await searchClient.searchSingleIndex({
        indexName: "LMSUser",
        // searchParams: {query: filters},
      });
      console.log(searchResults)
      // Update state with search results
      setResults(searchResults.hits);
      setError(null);
    } catch (err) {
      console.error('Error searching Algolia index:', err);
      setError(err.message || 'An error occurred while searching');
    }
  };

  return (
    <div>
      <h1>Algolia Search</h1>
      <input
        type="text"
        value={query}
        onChange={(e) => setQuery(e.target.value)}
        placeholder="Search by name"
      />
      <button onClick={handleSearch}>Search</button>
      {error && <p style={{ color: 'red' }}>Error: {error}</p>}
      <ul>
        
        {results.map((result) => (
          <li key={result.objectID}>
            {result.firstName}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default AlgoliaSearchComponent;
