import React, { useEffect } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import notificationSound from "../../assets/Audio/Whatsapp Notification Tone Download.mp3";

// Notification function
const notify = (title, body, imageUrl, ) => {
  const audio = new Audio(notificationSound);
  audio.play().catch((error) => {
    console.log(error)
  });
console.log(imageUrl, "egugefyujdgvuj")
  toast(
    <div style={{ textAlign: 'left' }}>
      <h4>{title}</h4>
      <p>{body}</p>
      {imageUrl && <img src={imageUrl} alt="Notification" style={{ width: '50%', borderRadius: '10px', marginTop: '10px' }} />}
      {/* {videoUrl && (
        <video controls style={{ width: '100%', borderRadius: '10px', marginTop: '10px' }}>
          <source src={videoUrl} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      )} */}
    </div>,
    {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      style: {
        backgroundColor: '#282c34',
        color: '#fff',
        borderRadius: '10px',
      },
    }
  );
};

// Main Component
const NotificationBox = ({ title, body, imageUrl, videoUrl }) => {
  useEffect(() => {
    if (title && body) {
      // Trigger the notification when title, body, imageUrl, or videoUrl is passed as props
      notify(title, body, imageUrl, videoUrl);
    }
  }, [title, body, imageUrl, videoUrl]); // Run useEffect when props change

  return (
    <div style={{ textAlign: 'center', marginTop: '50px' }}>
      <ToastContainer />
    </div>
  );
};

export default NotificationBox;
