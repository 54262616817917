import { getToggleButtonGroupUtilityClass } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
const useScreenSizes = () => {
  const [screenSize, setScreenSize] = useState(window.innerWidth);

  useEffect(() => {

    const handleResize = () => setScreenSize(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return screenSize;
};

const AboutStudyBuddy = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [isHovering, setIsHovering] = useState(false);
  const screenType = useSelector((state) => state.website.screenType);
  
 const { t } = useTranslation(); // Initialize translation hook
  const testimonials = [
    {
      imgSrc: require("../../assets/images/inspringGrowthEveryStep.png"),
      subHeader: t("Unleash Potential with StudyBuddy: Inspiring Growth Every Step"), // Add translation
      text: t("StudyBuddy empowers educators and learners alike with a dynamic digital platform that simplifies administration and enhances learning experiences. With innovative tools and personalized resources, we inspire growth and unlock every student's potential"), // Add translation
      name: "Peter Lee",
    },
    {
      imgSrc: require("../../assets/images/lmsTowardsEmpowerment.png"),
      subHeader: t("Transform Learning with StudyBuddy: Experience the Future Beyond an LMS Towards Empowerment"), // Add translation
      text: t("At StudyBuddy, we are dedicated to transforming education through a comprehensive digital ecosystem that empowers both students and educators."), // Add translation
      name: "Jane Doe",
    },
    {
      imgSrc: require("../../assets/images/digitallyConnected.png"),
      subHeader: t("Empowering a Digitally Connected Future with StudyBuddy"), // Add translation
      text: t("At StudyBuddy, our vision is to redefine education by creating a digitally connected ecosystem that empowers students and educators alike."), // Add translation
      name: "John Smith",
    },
  ];
  const styles = {
    container: {
      height: screenType==="phone"  ? "95vh" :  screenType==="smallPhone" ? "105vh":"75vh",
      width: screenType==="phone" || screenType==="smallPhone" ? "90vw" : "95vw",
      margin: "0 auto",
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding:"15px",
      overflow: 'hidden',
      backgroundColor: "#ffffff",
      boxShadow: "10px 10px 15px rgba(0, 0, 0, 0.3)",
      borderRadius:"10px",
      fontFamily:"raleway"
    },
    testimonialSection: {
      display: 'flex',
      flexDirection: screenType==="phone" ||screenType==="smallPhone" ? 'column' : 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: screenType==="phone" || screenType==="smallPhone" ? '90%' : '80%',
      height: '100%',
      fontFamily: 'Montserrat, sans-serif',
      padding: screenType==="phone" || screenType==="smallPhone" ? '8px' : '20px',
    },
    tabs: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      listStyle: 'none',
      marginRight: screenType==="phone" || screenType==="smallPhone" ? '5px' : '20px',
      flex: '1',
    },
    tabItem: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',
      cursor: 'pointer',
      marginBottom: '10px',
      transition: 'transform 0.3s',
    },
    img: {
      width: screenType==="phone" ? "120px" : screenType==="smallPhone" ? "100px" : screenType==="tablet"? "16vh":  screenType==="tab"? "16vh":  '10vw',
      height: screenType==="phone" ? '120px' :  screenType==="smallPhone" ? "100px": screenType==="tablet"? "15vh": screenType==="tab"? "15vh": '20vh',
      objectFit: 'contain',
      borderRadius: '50%',
      transition: 'transform 0.3s',
    },
    middleImage: {
      marginLeft: screenType==="phone"  ? '-180px' :  screenType==="smallPhone"? "-190px": '-220px',
    },
    carouselContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      marginLeft:"10vw",
      flex: '2',
      boxShadow: "10px 10px 15px rgba(0, 0, 0, 0.1)",
      textAlign: 'center',
    },
    subHeader: {
      fontSize: screenType==="phone" || screenType==="smallPhone" ? '14px' : '18px',
      color: 'rgba(0, 0, 0, .5)',
      fontWeight: 600,
    },
    header: {
      fontSize: screenType==="phone"  ? '20px' : screenType==="smallPhone"?"16px" : '32px',
      fontWeight: 700,
      marginTop: '8px',
      marginBottom: '8px',
       fontFamily:"raleway"
    },
    quoteWrapper: {
      marginTop: '8px',
      padding: '0 8px',
       fontFamily:"raleway"
     
    },
    quoteText: {
      fontSize: screenType==="phone" || screenType==="smallPhone" ? '12px' : '16px',
      lineHeight: '1.5',
      fontWeight: 500,
      color: 'rgba(0, 0, 0, 0.7)',
       fontFamily:"raleway"
    },
    quoteAuthor: {
      marginTop: '8px',
      fontSize: screenType==="phone" || screenType==="smallPhone" ? '14px' : '18px',
      fontWeight: 700,
      color: '#000',
    },
    indicators: {
      display: 'flex',
      justifyContent: 'center',
      paddingTop: '15px',
    },
    indicator: {
      display: 'inline-block',
      background: '#ffffff',
      height: '5px',
      width: screenType==="phone" || screenType==="smallPhone" ? '15px' : '30px',
      margin: '0 5px',
      cursor: 'pointer',
      opacity: 0.5,
      verticalAlign: 'middle',
    },
    activeIndicator: {
      opacity: 1,
    },
  };

  const startAnimation = () => {
    if (!isHovering) {
      setActiveIndex(prevIndex => (prevIndex + 1) % testimonials.length);
    }
  };

  useEffect(() => {
    const intervalId = setInterval(startAnimation, 2000);
    return () => clearInterval(intervalId);
  }, [testimonials.length, isHovering]);

  return (
    <div style={styles.container}>
      <div style={styles.testimonialSection}>
        <div className="col-lg-6 d-none d-lg-block">
          <ul style={styles.tabs} className="tabs">
            {testimonials.map((testimonial, index) => (
              <li
                key={index}
                style={{
                  ...styles.tabItem,
                  ...(index === 1 && styles.middleImage),
                }}
                onClick={() => setActiveIndex(index)}
                onMouseEnter={() => setIsHovering(true)}
                onMouseLeave={() => setIsHovering(false)}
              >
                <img
                  src={testimonial.imgSrc}
                  alt={ t(`Testimonial from ${testimonial.name}`)}
                  style={{
                    ...styles.img,
                    transform: activeIndex === index ?  'scale(1.15)' : 'scale(1)',
                    boxShadow: activeIndex === index ? "10px 10px 15px rgba(0, 0, 0, 0.3)":"",
                  }}
                />
              </li>
            ))}
          </ul>
        </div>

        <div style={styles.carouselContainer}>
        <h1 style={styles.header}>{t(testimonials[activeIndex].subHeader)}</h1>
        <div style={styles.quoteWrapper}>
          <p style={styles.quoteText}>{t(testimonials[activeIndex].text)}</p>
        </div>
          <ul style={styles.indicators}>
            {testimonials.map((_, index) => (
              <li
                key={index}
                style={{
                  ...styles.indicator,
                  ...(activeIndex === index && styles.activeIndicator),
                }}
                onClick={() => setActiveIndex(index)}
              />
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default AboutStudyBuddy;
