import React, { useState, useEffect } from "react";
import Granite from "../../assets/images/stones.png";
import BG from "../../assets/images/background.png";
import grass from "../../assets/images/grass.png";
import useScreenSizes from "../../utils/useScreenSize";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { firestore } from "../../firebase";
import { collection, addDoc, doc } from "firebase/firestore";

const Footer = () => {
  const screen = useScreenSizes();
  const navigate = useNavigate();
  const screenType = useSelector((state) => state.website.screenType);

  const section1 = [
    {
      heading: "Solutions",
      lists: ["LMS", "ERP", "WEBSITE", "SMARTCLASS", "SMARTSCHOOL"],
    },
  ];

  const sections2 = [
    {
      heading: "Info",
      lists: ["CONTACT US", "CAREER" , "GET STARTED"],
    },
  ];
  const sections3 = [
    {
      heading: "Policies",
      lists: ["PRIVACY POLICY", "TERMS OF USE", "REFUND POLICY"],
    },
  ];

  const Icon = [
    // {src : require("../../assets/Icons/facebook.png"), name: "facebook"},
    { src: require("../../assets/Icons/twitter.png"), name: "twitter" },
    // {src : require("../../assets/Icons/backArrow.png"), name: "youtube"},
    { src: require("../../assets/Icons/instagram.png"), name: "instagram" },
    { src: require("../../assets/Icons/youtube.png"), name: "youtube" },
    { src: require("../../assets/Icons/linkedin.png"), name: "linkedin" },
  ];

  const [hoveredItem1, setHoveredItem1] = useState({
    sectionIndex1: null,
    itemIndex1: null,
  });
  const [hoveredItem21, setHoveredItem21] = useState({
    sectionIndex2: null,
    itemIndex2: null,
  });
  const [hoveredItem3, setHoveredItem3] = useState({
    sectionIndex3: null,
    itemIndex3: null,
  });

  const [hoveredIcon, setHoveredIcon] = useState(null);
  const [hoveredSubscribe, setHoveredSubscribe] = useState("");
  const hover = true;

  const Enter = (hover) => {
    setHoveredSubscribe(hover);
  };

  const Leave = () => {
    setHoveredSubscribe(null);
  };

  const handleSolution = (item) => {
    navigate("/solution", {
      state: { section: item },
    });
  };

  const handleNavigatePolicies = (item) => {
    console.log(item);
    if (item == "PRIVACY POLICY") {
      navigate("/privacyPolicy");
    }
    if (item == "TERMS OF USE") {
      navigate("/termofuse");
    }
    if (item == "REFUND POLICY") {
      navigate("/refundPolicy");
    }
  };

  const [email, setEmail] = useState("");

  // Regex to validate the email format
  const isValidEmail = /^[a-zA-Z0-9._%+-]+@gmail\.com$/.test(email);

  const handleSubscribe = async () => {
   
    if (!isValidEmail) {
      alert("Please enter a valid Gmail address.");
      return;
    }

    try {
      // Add the Gmail address to Firestore
      await addDoc(collection(firestore, "subscribers"), {
        email: email,
        timestamp: new Date(),
      });
      alert("Subscribed successfully!");
      setEmail(""); // Clear the input field
    } catch (error) {
      console.error("Error adding document: ", error);
      alert("An error occurred. Please try again.");
    }
  };

  const handleNavigateInfo = (item) => {
    navigate("/info", {
      state: { section: item },
    });
  };
  const SubscribeStyle = () => ({
    transform: hoveredSubscribe ? "scale(1.2)" : "scale(1.0)",
    transition: "transform 0.3s ease",
  });
  const handleMouseEnter1 = (sectionIndex1, itemIndex1) => {
    setHoveredItem1({ sectionIndex1, itemIndex1 });
  };
  const handleMouseEnter21 = (sectionIndex2, itemIndex2) => {
    setHoveredItem21({ sectionIndex2, itemIndex2 });
  };
  const handleMouseEnter3 = (sectionIndex3, itemIndex3) => {
    setHoveredItem3({ sectionIndex3, itemIndex3 });
  };
  const handleNavigateSocialLink = (item) => {
    const urls = {
      instagram: "https://www.instagram.com/histudybuddy/",
      // facebook: "https://www.facebook.com",
      twitter: "https://x.com/histudybuddy",
      youtube: "https://www.youtube.com/channel/UCow6TQXh81oWQGfhqyrPtwA",
      linkedin: "https://www.linkedin.com/company/histudybuddy/",
    };

    if (urls[item.name]) {
      window.open(urls[item.name], "_blank"); // Open in a new tab
    } else {
      console.warn(`No URL defined for ${item.name}`);
    }
  };

  const handleMouseEnterIcon = (Icon) => {
    setHoveredIcon(Icon);
  };

  const handleMouseLeave1 = () => {
    setHoveredItem1({ sectionIndex1: null, itemIndex1: null });
  };

  const handleMouseLeave21 = () => {
    setHoveredItem21({ sectionIndex2: null, itemIndex2: null });
  };
  const handleMouseLeave3 = () => {
    setHoveredItem3({ sectionIndex3: null, itemIndex3: null });
  };

  const handleMouseLeaveIcon = () => {
    setHoveredIcon(null);
  };

  const hoveredStyle1 = (sectionIndex1, itemIndex1) => ({
    color:
      hoveredItem1.sectionIndex1 === sectionIndex1 &&
      hoveredItem1.itemIndex1 === itemIndex1
        ? "#16325B"
        : "white",
    transition: "color 0.2s ease",
    cursor: "pointer",
    fontWeight: 700,
  });
  const hoveredStyle21 = (sectionIndex2, itemIndex2) => ({
    color:
      hoveredItem21.sectionIndex2 === sectionIndex2 &&
      hoveredItem21.itemIndex2 === itemIndex2
        ? "#16325B"
        : "white",
    transition: "color 0.2s ease",
    cursor: "pointer",
    fontWeight: 700,
  });

  const hoveredStyle3 = (sectionIndex3, itemIndex3) => ({
    color:
      hoveredItem3.sectionIndex3 === sectionIndex3 &&
      hoveredItem3.itemIndex3 === itemIndex3
        ? "#16325B"
        : "white",
    transition: "color 0.2s ease",
    cursor: "pointer",
    fontWeight: 700,
  });

  const hoveredIconStyle = (Icon) => ({
    opacity: hoveredIcon === Icon ? "1.0" : "0.2",
    transform: hoveredIcon === Icon ? "scale(1.2)" : "scale(1.0)",
    transition: "color 0.2s ease",
    cursor: "pointer",
  });

  const styles = {
    footer: {
      maxWidth: "100%",
      backgroundImage: `linear-gradient(180deg,rgba(199, 152, 95, 1) 10%, rgba(199, 152, 95, 0.9) 35%, rgba(255,0,0, 0) 100%),url(${Granite})`,
      backgroundRepeat: "repeat",
      display: "flex",
      flexDirection: "column",
      position: "relative",
      zIndex: 1,
      opacity: 1,
      fontFamily: "raleway",
    },
    container1: {
      display: "flex",
      flexDirection:
        screenType === "smallPhone"
          ? "column"
          : screenType === "phone"
          ? "column"
          : screenType === "tab"
          ? "column"
          : screenType === "tablet"
          ? "column"
          : "row",
      maxWidth: "100%",
      margin:
        screenType === "phone" ||
        screenType === "smallPhone" ||
        screenType === "tab" ||
        screenType === "tablet"
          ? 15
          : "0px 20px",
    },
    container2: {
      display: "flex",
      maxWidth: "100%",
      flexDirection:
        screenType === "tab" || screenType === "tablet" ? "row" : "column",
      margin:
        screenType === "smallPhone" ||
        screenType === "phone" ||
        screenType === "tab" ||
        screenType === "tablet"
          ? null
          : "",
      alignItems: "center",
      justifyContent:
        screenType === "smallPhone" || screenType === "phone"
          ? null
          : screenType === "tab" || screenType === "tablet"
          ? "space-between"
          : "space-around",
      padding:
        screenType === "phone" ||
        screenType === "smallPhone" ||
        screenType === "tab" ||
        screenType === "tablet"
          ? "20px"
          : "",
    },
    container3: {
      height: 200,
      width: 300,
      marginTop: 10,
    },
    container4: {
      //paddingTop:20,
      flexDirection: "column",
      display: "flex",
      width:
        screenType === "tab"
          ? "260px"
          : screenType === "tablet"
          ? "300px"
          : "270px",
      marginRight: "20px",
      marginLeft:
        screenType === "tab" || screenType === "tablet" ? "20px" : null,
      marginBottom:
        screenType === "smallPhone" || screenType === "phone" ? "30px" : null,
    },
    container5: {
      display: "flex",
      //  justifyContent: 'space-between',
      rowGap: "20px",
      //alignItems:"flex-start",
      width: screenType === "tab" || screenType === "tablet" ? "93%" : "80%",
      padding:
        screenType === "smallPhone" || screenType === "phone"
          ? null
          : screenType === "tab" || screenType === "tablet"
          ? "20px"
          : "50px",
      flexDirection: "column",
      //columnGap:"15px",
      alignSelf:
        screenType === "smallPhone" || screenType === "phone" ? "center" : null,
    },
    container6: {
      display: "flex",
      position:
        screenType === "tab" || screenType === "tablet" ? "absolute" : "",
      left: "370px",
      top: "20px",

      flexDirection: "column",
      width: "100%",
    },
    container7: {
      display: "flex",
      flexDirection: "row",
      marginTop: 10,
      alignItems: "center",
    },
    container8: {
      display: "flex",
      flexDirection: "column",
      fontWeight: 700,
      color: "white",
      fontSize: 18,
    },
    container9: {
      display: "flex",
      flexDirection: "row",
      marginTop: 10,
      alignItems: "center",
      alignItems: "center",
    },
    container10: {
      width: "30%",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-evenly",
      alignItems: "center",
    },
    box1: {
      flexDirection: "column",
      backgroundColor: "white",
      fontWeight: "bold",
      width: "300px",
      height: "100px",
      paddingTop: 20,
      alignItems: "center",
      display: "flex",
      borderRadius: 15,
      backgroundImage: `url(${BG})`,
      backgroundSize: "cover",
    },
    box2: {
      width: "100%",
      height: 30,
      borderRadius: 15,
      backgroundImage: `url(${grass})`,
      backgroundRepeat: "repeat",
      backgroundSize: "contain",
    },
    box3: {
      display: "flex",
      flexDirection: "column",
    },
    box4: {
      display: "flex",
      flexDirection: "column",
    },
    box5: {
      flexDirection: "column",
      marginTop: screenType === "tab" || screenType === "tablet" ? null : 50,
      //marginRight:10,
      alignItems: "flexstart",
      display: "flex",
      alignSelf: screen > 700 && screen < 1200 ? "flex-start" : "center",
    },
    box6: {
      backgroundColor: "#16325B",
      width: "280px",
      height: "100px",
      paddingTop: 20,
      paddingLeft: 20,
      borderRadius: 15,
    },
    paragraph1: {
      fontWeight: 700,
      color: "white",
      textAlign: "justify",
      fontSize: 18,
    },
    paragraph2: {
      color: "white",
      fontSize: screenType === "smallPhone" ? 16 : 18,
      fontWeight: 700,
    },
    heading1: {
      color: "#16325B",
      marginBottom: 5,
    },
    subfooter: {
      height:
        screenType === "smallPhone"
          ? "150px"
          : screenType === "phone"
          ? "150px"
          : screenType === "tab"
          ? "100px"
          : screenType === "tablet"
          ? "100px"
          : "50px",
      backgroundColor: "white",
      width: "100%",
      display: "flex",
      justifyContent: "space-evenly",
      alignItems: "center",
      fontWeight: 700,
      flexDirection:
        screenType === "smallPhone"
          ? "column"
          : screenType === "phone"
          ? "column"
          : screenType === "tab"
          ? "column"
          : screenType === "tablet"
          ? "column"
          : "row",
    },
    wordmark: {
      height: "80px",
    },
    icons: {
      height: 25,
      width: 25,
      marginRight: 10,
    },
    image: {
      height: 200,
    },
    Imagecontainer: {
      display: "flex",
      justifyContent: "flex-end",
      position:
        screenType === "smallPhone" ||
        screenType === "phone" ||
        screenType === "tab" ||
        screenType === "tablet"
          ? null
          : "absolute",
      bottom:
        screenType === "smallPhone" ||
        screenType === "phone" ||
        screenType === "tab" ||
        screenType === "tablet"
          ? null
          : 0,
      right:
        screenType === "smallPhone" ||
        screenType === "phone" ||
        screenType === "tab" ||
        screenType === "tablet"
          ? null
          : 0,
    },
    InputText: {
      display: "flex",
      position: "relative",
      alignItems: "center",
      padding: 10,
      border: "1px solid #ccc",
      borderRadius: 20,
      backgroundColor: "white",
      fontWeight: "bold",
    },
    input: {
      flex: 1,
      padding: 5,
      outline: "none",
      border: "none",
    },
    button: {
      padding: "5px 10px",
      backgroundColor: "#ff5722",
      color: "white",
      fontWeight: 700,
      border: "none",
      cursor: "pointer",
      borderRadius: 15,
      position: "absolute",
      right: 10,
    },
    logobox: {
      display: "flex",
      position: "relative",
      flexDirection:
        screenType === "tab" || screenType === "tablet" ? "row" : "column",
      maxWidth:
        screenType === "smallPhone" ||
        screenType === "phone" ||
        screenType === "tab" ||
        screenType === "tablet"
          ? "100%"
          : "350px",
      rowGap: "30px",
      marginLeft:
        screenType === "smallPhone" ||
        screenType === "phone" ||
        screenType === "tab" ||
        screenType === "tablet"
          ? null
          : "40px",
      justifyContent:
        screenType === "smallPhone" ||
        screenType === "phone" ||
        screenType === "tab" ||
        screenType === "tablet"
          ? "space-between"
          : null,
    },
  };

  return (
    <div style={styles.footer}>
      <div style={styles.container1}>
        <div style={styles.container2}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div style={styles.box1}>
              <img
                src={require("../../assets/images/wordmark1.png")}
                style={styles.wordmark}
                alt="logo"
              />
              <div style={styles.box2} />
            </div>
            <div style={styles.container3}>
              <p style={styles.paragraph1}>
                A learning experience that goes beyond textbooks and lectures. A
                platform for a supportive community where you can connect with
                classmates, teachers, and even mentors – all on one platform.
                That’s StudyBuddy!
              </p>
            </div>
          </div>
        </div>

        <div style={styles.container5}>
          <div style={styles.container6}>
            <h2 style={styles.heading1}>Contact Us</h2>
            <div style={styles.container7}>
              <img
                src={require("../../assets/Icons/Address.png")}
                style={styles.icons}
                alt="address"
              />
              <p style={styles.paragraph2}>
                1st Floor, 146,Dhan Mandi, HMO, Rajasthan, India{" "}
              </p>
            </div>
            <div style={styles.container9}>
              <img
                src={require("../../assets/Icons/Call.png")}
                style={styles.icons}
                alt="call"
              />
              <p style={styles.paragraph2}>+91 8442002022</p>
            </div>
            <div style={styles.container9}>
              <img
                src={require("../../assets/Icons/mail.png")}
                style={styles.icons}
                alt="mail"
              />
              <p style={styles.paragraph2}>support@studybuddy.education</p>
            </div>
          </div>

          <div style={styles.container4}>
            <h2 style={styles.heading1}>Stay Connected</h2>
            <div style={styles.InputText}>
              <input
                type="email"
                placeholder="Enter email address"
                style={styles.input}
              />
              <button
                style={{ ...styles.button, ...SubscribeStyle() }}
                onMouseEnter={() => Enter(hover)}
                onClick={handleSubscribe}
                onMouseLeave={Leave}
              >
                Subscribe
              </button>
            </div>
          </div>
        </div>

        <div style={styles.logobox}>
          {/* {screen > 700 && screen < 1200 ? (
            <div style={styles.container4}>
              <h2 style={styles.heading1}>Stay Connected</h2>
              <div style={styles.InputText}>
                <input
                  type="email"
                  placeholder="Enter email address"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  style={styles.input}
                />
                <div
                  style={{ ...styles.button, ...SubscribeStyle() }}
                  onMouseEnter={() => Enter(hover)}
                  onClick={handleSubscribe}
                  onMouseLeave={Leave}
                >
                  Subscribe
                </div>
              </div>
            </div>
          ) : ( */}
            <div style={styles.box5}>
              <h3
                style={{
                  display: "flex",
                  fontFamily: "MarkerFelt",
                  maxWidth: "300px",
                  justifyContent: "center",
                }}
              >
                Powered By
              </h3>
              <div style={styles.box6}>
                <img
                  src={require("../../assets/images/logo30.png")}
                  style={{ height: 80 }}
                  alt="logo"
                />
              </div>
            </div>
          {/* )} */}
          <div style={styles.Imagecontainer}>
            <img
              src={require("../../assets/images/insect.png")}
              style={styles.image}
              alt="linux"
            />
          </div>
        </div>
      </div>
      <div style={styles.subfooter}>
        <p>Copyright 2024 © StudyBuddy | All Rights Reserverd</p>

        <div>
          <div>
            {Icon.map((item) => (
              <img
                src={item.src}
                style={{ ...styles.icons, ...hoveredIconStyle(`${item.name}`) }}
                alt={item.name}
                onMouseEnter={() => handleMouseEnterIcon(`${item.name}`)}
                onMouseLeave={handleMouseLeaveIcon}
                onClick={() => handleNavigateSocialLink(item)}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
