import React, { useState, useEffect } from "react";
import { Box, Typography,} from "@mui/material";
import ThemeDashboardCard from "../../Components/test/ThemeDashboard";
import { useSelector } from "react-redux";
import { collection,  getDocs } from "firebase/firestore";
import { firestore } from "../../firebase";
import { useTranslation } from "react-i18next";


function LMS() { 

  const screenType = useSelector((state) => state.website.screenType);
  const styles = {
    container: {
      display: "flex",
      flexDirection: screenType==="smallPhone"||screenType==="phone"? "column": "row",
      width:  screenType==="smallPhone"||screenType==="phone"? "100vw": "100%",
      height: "100%",
      padding: "20px",
      gap:screenType==="smallPhone"||screenType==="phone"? "":  "20px",
      overflow: "hidden",
    },
    section: {
      display: "flex",
      flexDirection: "column",
      flexWrap: "wrap",
    
      gap: "20px",
      borderRadius: "10px",
      padding: "20px",
      boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
      backgroundColor: "#f9f9f9",
    },
    academicSection: {
      width: screenType==="phone"||screenType==="smallPhone"?"100%"  :"70%",
      justifyContent: "center",
    },
    financeSection: {
      width: screenType==="smallPhone"||screenType==="phone"?"" : "30%",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      padding: "10px 20px",
    },
    cardWrapper: {
      flexBasis: "calc(50% - 20px)", // Ensures two cards per row
   
    },
    noticeSection: {
      width: "95%",
      padding: "20px",
      marginLeft: "20px",
      backgroundColor: "#ffecb3",

      borderRadius: "10px",
      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
    },

    notificationBox: {
      width: "97%",
      padding: "20px",
      marginLeft: "20px",
      backgroundColor: "#ffecb3",
      borderRadius: "10px",
      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
    },
    notificationWrapper: {
      position: "relative",
      height: "140px",
      overflow: "hidden",
    },
    notificationSlider: {
      display: "flex",
      flexDirection: "column",
      position: "absolute",
      width: "100%",
      animation: `scroll 15s linear infinite`, // Infinite scrolling animation
    },
    notificationItem: {
      padding: "10px",
      marginBottom: "8px",
      display:"flex",
      width:"100%",
      backgroundColor: "rgba(255, 255, 255, 0.7)",
      borderRadius: "4px",
      boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
    },
    title: {
      fontSize: "1.25rem",
      fontWeight: "bold",
      marginBottom: "1rem",
      color: "#333",
    },
  };

  // Sample notification array
  const [notifications,setNotifications] = useState([
    "Mid-term exams will start from March 10, 2024.",
    "Project submission deadline extended to March 20, 2024.",
    "Join the webinar on 'AI in Education' on March 15, 2024.",
    "New course on 'Data Science' is now available.",
    "Upcoming holiday: Spring Break on March 25, 2024.",
    "Submit feedback for the recent workshop by March 5, 2024.",
  ]);

  const [currentIndex, setCurrentIndex] = useState(0);
  const [documentData, setDocumentData] = useState();
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % notifications.length);
    }, 2000); 
    
    return () => clearInterval(interval);
  }, [notifications.length]);
  
  useEffect(() => {
    const fetchBatches = async () => {
      try {
        const batchCollection = collection(firestore, "batch");
        const batchSnapshot = await getDocs(batchCollection);
        const batchList = batchSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setDocumentData(batchList); // Save the documents in state
      } catch (error) {
      }
    };

    fetchBatches();
  }, []);
   const [presentPercentage, setPresentPercentage] = useState(0);

  useEffect(() => {
    if (documentData && Array.isArray(documentData) && documentData.length > 0) {
      // Initialize counters for aggregation
      let totalAbsent = 0;
      let totalStudents = 0;

      // Loop through all document data and aggregate values
      documentData.forEach((doc) => {
        if (doc.aggregation && doc.aggregation.discipline) {
          totalAbsent += doc.aggregation.discipline.absent || 0;  // Safely add absent students
          totalStudents += doc.aggregation.noOfStudents || 0;     // Safely add total students
        }
      });

      const totalPresent = totalStudents - totalAbsent;


    const presentPercentage = (totalPresent / totalStudents) * 100;
          setPresentPercentage ( presentPercentage );

      // Log the aggregated results to the console
    } else {
    }
  }, [documentData]); // Dependency on documentData to trigger when it changes
const {t} = useTranslation();
  return (
    <>

<style>
        {`
          @keyframes scroll {
            0% { transform: translateY(0); }
            100% { transform: translateY(-${notifications.length * 49.5}px); }
          }
        `}
      </style>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          // width: "96%",
          height: "100%",
          padding: 2,
        }}
      >
    <div style={{display:"flex", backgroundColor:"white", justifyContent:"center", alignItems:"center", flexDirection:"column", padding:"20px", borderRadius:"10px",    boxShadow: "20px 20px 25px rgba(0, 0, 0, 0.3)", margin:"2vw ", width:"95%"}}>

        <Box sx={{ textAlign: "center", marginBottom: 4 }}>
          <Typography
            variant="h2"
            component="h1"
            sx={{ fontWeight: "bold", color: "#3b3d4b" }}
          >
            Welcome to StudyBuddy
          </Typography>
          <Typography variant="h6" sx={{ color: "#37474f" }}>
            Your ultimate learning companion
          </Typography>
        </Box> 
        </div>

        <div style={styles.notificationBox}>
          <h5 style={styles.title}>📢 {t("Important Notices")}</h5>
          <div style={styles.notificationWrapper}>
          <div style={styles.notificationSlider}>
            {notifications.concat(notifications).map((notification, index) => (
              <div key={index} style={styles.notificationItem}>
                {t(notification)}
              </div>
            ))}
          </div>
        
            
          </div>
         
        </div>
      
        <div style={styles.container}>  
  {/* Academic Section */}
  <div style={{ ...styles.section, ...styles.academicSection }}>
    <h2 style={styles.heading}>{t("Academic Session")}</h2>
    <div style={{ display: "flex", flexWrap: "wrap", gap: "20px", justifyContent: "end" }}>
      <div style={styles.cardWrapper}>
        <ThemeDashboardCard fill ={presentPercentage} />
      </div>
      <div style={styles.cardWrapper}>
        <ThemeDashboardCard  />
      </div>
      <div style={styles.cardWrapper}>
        <ThemeDashboardCard />
      </div>
      <div style={styles.cardWrapper}>
        <ThemeDashboardCard />
      </div>
    </div>
  </div>

          {/* Finance Details Section */}
          <div style={{ ...styles.section, ...styles.financeSection }}>
            <div>

          <h2 style={styles.heading}>{t("Academic Session")}</h2>
            </div>
            <div style={{ display: "flex", flexWrap: "wrap", flexDirection:"column", gap: "20px", justifyContent: "center" }}>

            <div style={styles.cardWrapper}>
           <ThemeDashboardCard />
            </div>
            <div style={styles.cardWrapper}>
           <ThemeDashboardCard />
            </div>
            </div>
          </div>
        </div>
      </Box>
    </>
  );
}

export default LMS;
