import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import dropdownArrow from "../../assets/images/dropdownArrow.png";
import dropdownUpArrow from "../../assets/images/dropdownUpArrow.png";
import aboutIcon from "../../assets/images/about.png";
import contactIcon from "../../assets/images/contact.png";
import useScreenSizes from "../../utils/useScreenSize";
import SidebarMobile from "./SidebarMobile";
import { useTranslation } from "react-i18next";

const SideBarScreen = () => {
  const [isPhone, setIsPhone] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [openSubmenu, setOpenSubmenu] = useState("");
  const location = useLocation();
  const screen = useScreenSizes();

  const headerHeight = useSelector((state) => state.website.headerHeight);
  const TitlebarHeight = useSelector((state) => state.website.TitlebarHeight);

  useEffect(() => {
    if (screen >= 950) {
      setIsPhone(false);
    } else {
      setIsPhone(screen <= 650);
    }
  }, [screen]);

  const handleMouseEnter = () => setIsExpanded(true);
  const handleMouseLeave = () => setIsExpanded(false);
  const handleSubmenuClick = (submenu) => {
    setOpenSubmenu(openSubmenu === submenu ? "" : submenu);
  };

  // Sidebar items array
  const sidebarItems = [
    {
      label: "Charts",
      icon: null,
      submenu: [
        { label: "Pie Charts", route: "/pie-charts" },
        { label: "Line Charts", route: "/line-charts" },
      ],
    },
    {
      label: "Documentation",
      icon: null,
      route: "/documentation",
    },
    {
      label: "Calendar",
      icon: null,
      route: "/calendar",
    },
    {
      label: "About",
      icon: aboutIcon,
      submenu: [
        { label: "About Section", route: "/about" },
        { label: "Team", route: "/team" },
      ],
    },
    {
      label: "Contact",
      icon: contactIcon,
      route: "/contact",
    },
  ];

  const sidebarStyles = {
    width: location.pathname === "/website" ? 0 : isPhone ? "20%" : isExpanded ? "20%" : "4%",
    opacity: isExpanded ? 1 : 0.6,
    transition: "width 0.3s ease, opacity 0.3s ease",
    position: isPhone ? "absolute" : "relative",
    right: 0,
    backgroundColor: isPhone ? "transparent" : "#E5F1E3",
    zIndex: 10,
    overflow: "hidden",
  };

  const menuItemStyles = {
    display: "flex",
    alignItems: "center",
    padding: "4px 10px",
    textDecoration: "none",
    color: "black",
    fontWeight: "bold",
    cursor: "pointer",
    justifyContent: "space-between",
    borderRadius: "10px",
  };

  const iconStyles = {
    width: "24px",
    height: "24px",
    marginRight: "10px",
  };

  const arrowLogo = {
    width: "15px",
    height: "12px",
  };

  const { t } = useTranslation(); 
  const renderSidebarItems = () => {
    return sidebarItems.map((item, index) => (
      <div key={index} style={{ marginBottom: "10px" }}>
        <span
          style={menuItemStyles}
          onClick={() => item.submenu && handleSubmenuClick(t(item.label))}
        >
          {item.icon && <img src={item.icon} alt={`${item.label} Icon`} style={iconStyles} />}
          {isExpanded && item.label}
          {item.submenu && (
            <img
              src={openSubmenu === item.label ? dropdownUpArrow : dropdownArrow}
              alt="Dropdown Arrow"
              style={arrowLogo}
            />
          )}
        </span>
        {item.submenu && openSubmenu === item.label && (
          <div style={{ paddingLeft: "10px" }}>
            {item.submenu.map((subitem, subindex) => (
              <Link key={subindex} to={subitem.route} style={menuItemStyles}>
                {t(subitem.label)}
              </Link>
            ))}
          </div>
        )}
        {!item.submenu && item.route && (
          <Link to={item.route} style={menuItemStyles}>
            {t(item.label)}
          </Link>
        )}
      </div>
    ));
  };

  return (
    <div
      style={sidebarStyles}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {!isPhone ? <div>{renderSidebarItems()}</div> : <SidebarMobile />}
    </div>
  );
};

export default SideBarScreen;
