import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  auth,
} from "../../firebase";
import { useDispatch, useSelector } from "react-redux";
import { setSideBar, setUser } from "../../reduxStore/slices/authSlice";
import ThemeButton from "../../Components/ResuableThemeComponent/ThemeButton";
import { useTranslation } from "react-i18next";

const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorModalActive, setErrorModalActive] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [forgotPasswordActive, setForgotPasswordActive] = useState(false);
  const [resetEmail, setResetEmail] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = useSelector((state) => state.auth.token);
  const screenType = useSelector((state) => state.website.screenType);

  useEffect(() => {
    if (token) {
      dispatch(setSideBar(true));
    } else {
      dispatch(setSideBar(false));
    }
  }, [token, dispatch]);

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const signIn = async () => {
    if (!email) {
      setErrorMessage("Please enter your email.");
      setErrorModalActive(true);
      return;
    }
    if (!validateEmail(email)) {
      setErrorMessage("Please enter a valid email address.");
      setErrorModalActive(true);
      return;
    }
    if (!password) {
      setErrorMessage("Please enter your password.");
      setErrorModalActive(true);
      return;
    }

    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      
      const user = userCredential.user;
      const token = await user.getIdToken();
      dispatch(setUser({ user: user.toJSON(), token }));

      console.log(token);
      setEmail("");
      setPassword("");
      navigate("/LMS");
      dispatch(setSideBar(true));
    } catch (error) {
      setErrorMessage(`Error: ${error.message}`);
      setErrorModalActive(true);
    }
  };

  const handleForgotPassword = () => {
    setForgotPasswordActive(true);
  };

  const { t } = useTranslation();
const handleLogin =()=>{
  setErrorMessage("Please enter correct password.");
  setErrorModalActive(true);
  setErrorMessage("Please enter a valid email address.");
}
const handleResetpasswordLogin =()=>{
  setErrorModalActive(true);
  setErrorMessage("Please enter a valid email address.");
}
  const handleResetPassword = async () => {
    if (!resetEmail) {
      setErrorMessage("Please enter your email for password reset.");
      setErrorModalActive(true);
      return;
    }
    if (!validateEmail(resetEmail)) {
      setErrorMessage("Please enter a valid email address.");
      setErrorModalActive(true);
      return;
    }

    try {
      alert(`Password reset link sent to ${resetEmail}`);
      setResetEmail("");
      setForgotPasswordActive(false);
    } catch (error) {
      setErrorMessage(`Error: ${error.message}`);
      setErrorModalActive(true);
    }
  };

  const handleImageClick = () => {
    navigate("/");
  };

  const styles = {
    container: {
      margin: 0,
      padding: 0,
      height: "100vh",
      width: "100vw",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontFamily: "'Roboto', sans-serif", // Modern font
      background: "#C7985F",
    },
    infoContainer: {
      width: "100%",
      maxWidth: "400px", // Max width for form container
      backgroundColor: "#ffffffcc", // Semi-transparent white background
      padding: "30px",
      borderRadius: "15px",
      boxShadow: "0 15px 30px rgba(0, 0, 0, 0.1)", // Soft shadow
      backdropFilter: "blur(10px)", // Subtle blur effect on background
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    heading: {
      fontSize: "28px",
      fontWeight: "700",
      color: "#333",
      marginBottom: "20px",
      textAlign: "center",
      letterSpacing: "1px",
    },
    input: {
      width: "100%",
      padding: "15px",
      margin: "12px 0",
      border: "2px solid #ddd",
      borderRadius: "8px",
      fontSize: "16px",
      outline: "none",
      transition: "border 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
      boxSizing: "border-box",
    },
    inputFocus: {
      borderColor: "#00b894",
      boxShadow: "0 0 8px rgba(0, 184, 148, 0.5)",
    },
    errorStyle: {
      marginTop: "20px",
      padding: "10px",
      borderRadius: "5px",
      backgroundColor: "#f8d7da",
      color: "#721c24",
      border: "1px solid #f5c6cb",
      textAlign: "center",
    },
    forgotPassword: {
      color: "#00b894",
      cursor: "pointer",
      marginTop: "10px",
      textDecoration: "underline",
    },
  };

  return (
    <div style={styles.container}>
      <div
        style={{
          display: "flex",
          width: "100vw",
          position: "absolute",
          top: "10px",
          marginTop:
            screenType === "desktop"
              ? "20px"
              : screenType === "phone" || screenType === "smallPhone"
              ? "20px"
              : screenType === "tab" || screenType === "tablet"
              ? "20px"
              : "",
          display: "flex",
          justifyContent: "end",
        }}
      >
        <img
          src={require("../../assets/Icons/close.png")}
          style={{
            height:
              screenType === "phone" || screenType === "smallPhone"
                ? "20px"
                : "30px",
            width: "auto",
            marginRight: "20px",
            cursor: "pointer",
          }}
          onClick={handleImageClick}
        />
      </div>
      <div style={styles.infoContainer}>
        <p style={styles.heading}>{t("Login")}</p>

        {!forgotPasswordActive && (
          <>
            <input
              title="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              style={{ ...styles.input, ...(email && styles.inputFocus) }}
            />
            <input
              title="password"
              placeholder="Password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              style={{ ...styles.input, ...(password && styles.inputFocus) }}
            />
            <ThemeButton
              title="login"
              text="Forgot Password?"
              func={handleLogin}
              width="50%"
              variant="blue"
            />
            <ThemeButton
              title="Reset Password"
              // onClick={handleForgotPassword}
              width="50%"
              variant="green"
              func={handleForgotPassword}
            />
          </>
        )}

        {forgotPasswordActive && (
          <div>
            <input
              title="resetEmail"
              placeholder="Enter your email for reset"
              value={resetEmail}
              onChange={(e) => setResetEmail(e.target.value)}
              style={styles.input}
            />
            <ThemeButton
              text="Reset Password"
              func={handleResetpasswordLogin}
              title="Reset Password"
              // onClick={handleForgotPassword}
              width="50%"
              variant="green"
            />
          </div>
        )}

        {errorModalActive && (
          <div style={styles.errorStyle}>
            <p>{errorMessage}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default LoginPage;