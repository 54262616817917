import React, { useState } from "react";
import { firestore } from "../../firebase"; // Make sure to import Firestore properly
import { collection, addDoc } from "firebase/firestore"; // Firestore methods for adding data
import { useTranslation } from 'react-i18next';
const ContactUs = () => {
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        subject: "",
        message: ""
    });
const { t } = useTranslation();
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault(); // Prevent the default form submission

        try {
            // Add the form data to Firestore
            await addDoc(collection(firestore, "userInfo"), {
                name: formData.name,
                email: formData.email,
                subject: formData.subject,
                message: formData.message,
                timestamp: new Date() // Add a timestamp
            });

            alert("Message sent successfully!");
            setFormData({ name: "", email: "", subject: "", message: "" }); // Clear the form
        } catch (error) {
            console.error("Error sending message: ", error);
            alert("An error occurred. Please try again.");
        }
    };

  

    return (
      <div style={styles.container}>
        <h1 style={styles.sectionTitle}>{t('Contact Us')}</h1>
        <div style={styles.contactWrapper}>
          <div style={styles.contactInfo}>
            <h2 style={styles.sectionTitle}>{t('Get in Touch')}</h2>
    
            <div style={styles.contactInfoItem}>
              <div style={styles.icon}>📍</div>
              <div style={styles.infoContent}>
                <h3 style={styles.infoTitle}>{t('Address')}</h3>
                <p style={styles.infoText}>1st Floor, 146, Dhan Mandi, HMO, Rajasthan, India</p>
              </div>
            </div>
    
            <div style={styles.contactInfoItem}>
              <div style={styles.icon}>📞</div>
              <div style={styles.infoContent}>
                <h3 style={styles.infoTitle}>{t('Phone')}</h3>
                <p style={styles.infoText}>+91 8442002022</p>
              </div>
            </div>
    
            <div style={styles.contactInfoItem}>
              <div style={styles.icon}>✉️</div>
              <div style={styles.infoContent}>
                <h3 style={styles.infoTitle}>{t('Email')}</h3>
                <p style={styles.infoText}>support@studybuddy.education</p>
              </div>
            </div>
          </div>
    
          <div style={styles.contactForm}>
            <h2 style={styles.sectionTitle}>{t('Send Message')}</h2>
            <form onSubmit={handleSubmit}>
              <div style={styles.formGroup}>
                <label style={styles.label} htmlFor="name">{t('Your Name')}</label>
                <input
                  style={styles.input}
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </div>
    
              <div style={styles.formGroup}>
                <label style={styles.label} htmlFor="email">{t('Email Address')}</label>
                <input
                  style={styles.input}
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>
    
              <div style={styles.formGroup}>
                <label style={styles.label} htmlFor="subject">{t('Subject')}</label>
                <input
                  style={styles.input}
                  type="text"
                  id="subject"
                  name="subject"
                  value={formData.subject}
                  onChange={handleChange}
                  required
                />
              </div>
    
              <div style={styles.formGroup}>
                <label style={styles.label} htmlFor="message">{t('Message')}</label>
                <textarea
                  style={styles.textarea}
                  id="message"
                  name="message"
                  rows="5"
                  value={formData.message}
                  onChange={handleChange}
                  required
                />
              </div>
    
              <button type="submit" style={styles.submitBtn}>{t('Send Message')}</button>
            </form>
          </div>
        </div>
      </div>
    );
};

const styles = {
    container: {
        maxWidth: "1200px",
        margin: "0 auto",
        padding: "2rem",
    },
    contactWrapper: {
        display: "flex",
        flexWrap: "wrap",
        gap: "2rem",
        marginTop: "3rem",
    },
    contactInfo: {
        flex: 1,
        minWidth: "300px",
        background: "#fff",
        padding: "2rem",
        borderRadius: "10px",
        boxShadow: "0 5px 15px rgba(0, 0, 0, 0.1)",
    },
    contactForm: {
        flex: 2,
        minWidth: "300px",
        background: "#fff",
        padding: "2rem",
        borderRadius: "10px",
        boxShadow: "0 5px 15px rgba(0, 0, 0, 0.1)",
        animation: "fadeIn 0.6s ease-out forwards",
    },
    sectionTitle: {
        color: "#2c3e50",
        marginBottom: "1.5rem",
        fontSize: "2rem",
        position: "relative",
        paddingBottom: "0.5rem",
    },
    contactInfoItem: {
        display: "flex",
        alignItems: "center",
        marginBottom: "1.5rem",
    },
    icon: {
        height: "40px",
        width: "50px",
        background: "#3498db",
        borderRadius: "50%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginRight: "1rem",
        color: "white",
    },
    infoContent: {},
    infoTitle: {
        color: "#2c3e50",
        fontSize: "1.1rem",
        marginBottom: "0.3rem",
    },
    infoText: {
        color: "#666",
    },
    formGroup: {
        marginBottom: "1.5rem",
    },
    label: {
        display: "block",
        marginBottom: "0.5rem",
        color: "#2c3e50",
        fontWeight: 500,
    },
    input: {
        width: "100%",
        padding: "0.8rem",
        border: "1px solid #ddd",
        borderRadius: "5px",
        fontSize: "1rem",
        transition: "border-color 0.3s ease",
    },
    textarea: {
        width: "100%",
        padding: "0.8rem",
        border: "1px solid #ddd",
        borderRadius: "5px",
        fontSize: "1rem",
        transition: "border-color 0.3s ease",
    },
    submitBtn: {
        background: "#3498db",
        color: "white",
        padding: "0.8rem 2rem",
        border: "none",
        borderRadius: "5px",
        fontSize: "1rem",
        cursor: "pointer",
        transition: "background-color 0.3s ease",
    },
};

export default ContactUs;
